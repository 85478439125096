import React, { useState }  from 'react';
import './../styles/Collapsible.css';
import "./../styles/Typography.css";
import "./../styles/App.css";
import Divider from "../components/Divider";

const Collapsible = (props) => {
  const [open, setOpen] = useState(props.open === "true" || false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <div className='flex-full-width collapsible-root' onClick={toggle}>
      <div className={open ? "collapsible-open collapsible-container" : "collapsible-closed collapsible-container"}>
        <div className="collapsible-content">
          <div className="h8-semibold grays-000">{props.label}</div>
          {open && (
            <div className="toggle body-m-regular grays-100">{props.content}</div>
          )}
        </div>
        <div className='toggle-button-background'>
          <img src={open ? "/img/minus-circle.svg" : "/img/plus-circle.svg"} alt="toggle-button" className="toggle-button" onClick={toggle} />
        </div>
      </div>
      {!open && <Divider height="2px" background='#F6F5F5' />}
    </div>
  );
};
export default Collapsible;