export const fleetData = {
    hero: {
        title: `قم بتحويل إدارة أسطولك باستخدام رؤية: التقنية التيليماتية المدعومة بالذكاء الاصطناعي`,
        description: 
            `تم تصميم حلولنا المتقدمة لتحسين كل جانب من جوانب عمليات الأسطول، 
            بدءًا من المراقبة المباشرة وحتى تحليل الأداء التفصيلي.`,
        ctaButton: {
            text: "كيف تعمل؟",
            path: "/technology"
        },
        image: "/img/hero-img.png"
    },
    features: [
        {
            title: "تتبع مباشر للمركبات",
            description: 
                "راقب موقع أسطولك وسرعته وحالته من خلال التحديثات المباشرة، مما يضمن التخطيط الفعال للمسار والتسليم في الوقت المناسب.",
            icon: "/img/record.svg"
        },
        {
            title: "رؤى الأداء",
            description: 
                `يمكنك الوصول إلى لوحات المعلومات القابلة للتخصيص والتقارير التفصيلية 
                لمراقبة أداء الأسطول وتحديد الاتجاهات واتخاذ القرارات الصحيحة.`,
            icon: "/img/chart.svg"
        },
        {
            title: "تعزيز سلامة السائق",
            description: 
                `قم بتحسين سلوك السائق من خلال التنبيهات والتعليقات بشأن الممارسات غير الآمنة 
                التي يتم إرسالها بشكل مباشر، مما يساعد على تقليل الحوادث وتعزيز القيادة الآمنة.`,
            icon: "/img/guard.svg"
        },
        {
            title: "التكامل السلس",
            description: 
                "تتكامل حلولنا بسهولة مع أنظمة إدارة الأسطول الحالية، مما يوفر انتقالًا سلسًا.",
            icon: "/img/refresh.svg"
        }
    ],
    additionalFeatures: [
        {
            tag: "ميزة أساسية",
            title: "كاميرات مراقبة عالية الوضوح",
            description: "التقط بيانات القيادة الهامة باستخدام الكاميرات المزدوجة عالية الدقة.",
            image: "/img/fleet-feat-1.png"
        },
        {
            tag: "ميزة أساسية",
            title: "التحليل المدعوم بالذكاء الاصطناعي",
            description: "استخدم الذكاء الاصطناعي المتقدم لمراقبة وتحليل سلوك السائق.",
            image: "/img/fleet-feat-2.png"
        },
        {
            tag: "ميزة أساسية",
            title: "تنبيهات مخصصة",
            description: "تلقي الإشعارات بشكل مباشر عن الأحداث والحوادث.",
            image: "/img/fleet-feat-3.png"
        },
        {
            tag: "ميزة أساسية",
            title: "تخزين سحابي آمن",
            description: "قم بتخزين البيانات والوصول إليها بشكل آمن من خلال نظامنا القائم على السحابة.",
            image: "/img/fleet-feat-4.png"
        }
    ],
    cta: {
        tag: "تواصل معنا",
        title: "جاهز لتحسين عمليات أسطولك؟",
        description: 
            `تعرف على كيف يمكن للرؤى المباشرة والتحليلات المتقدمة 
            أن تغير الطريقة التي تدير بها أسطولك. دعنا نقود عملياتك إلى آفاق جديدة.`,
        buttons: [
            {
                text: "احجز عرضًا توضيحيًا",
                color: "green",
                path: "/contact-us"
            }
        ],
        image: "/img/cta-img.png"
    }
};

export const insurersData = {
    hero: {
        title: "قم بتحسين إدارة المخاطر باستخدام تقنية رؤية",
        description: 
            `توفر تقنيتنا المتقدمة رؤى شاملة وحلولًا تعتمد على البيانات 
            لدعم عمليات التأمين الخاصة بك.`,
        ctaButton: {
            text: "كيف تعمل؟",
            path: "/technology"
        },
        image: "/img/Hero-img2.svg"
    },
    features: [
        {
            title: "تخفيض المطالبات",
            description: 
                `تطبيق التكنولوجيا المصممة لمنع الحوادث، 
                مما يساعد على تقليل تكرار وخطورة مطالبات التأمين.`,
            icon: "/img/key-feat-1.svg"
        },
        {
            title: "تحديد دقيق للمخاطر",
            description: 
                `استخدم البيانات التفصيلية للسائق والمركبة 
                لتقييم المخاطر بشكل أكثر دقة واتخاذ قرارات الاكتتاب الصحيحة.`,
            icon: "/img/key-feat-2.svg"
        },
        {
            title: "دعم الاكتتاب",
            description: 
                `استخدم بيانات الأداء الشاملة لتحسين عمليات الاكتتاب لديك 
                وتقييم المخاطر المحتملة بشكل أفضل.`,
            icon: "/img/key-feat-3.svg"
        },
        {
            title: "رؤى تعتمد على البيانات",
            description: 
                `يمكنك الوصول إلى التقارير التفصيلية والبيانات التاريخية 
                للحصول على فهم أوضح لعوامل الخطر وتحسين استراتيجيات إدارة المخاطر لديك.`,
            icon: "/img/key-feat-4.svg"
        }
    ],
    additionalFeatures: [
        {
            tag: "ميزة أساسية",
            title: "الإبلاغ الشامل عن المخاطر",
            description: "تحليل مفصل لأداء السائق والمركبة.",
            image: "/img/insurers-feat-1.png"
        },
        {
            tag: "ميزة أساسية",
            title: "الوصول إلى البيانات التاريخية",
            description: 
                `الوصول الآمن إلى البيانات طويلة المدى لتحليل الاتجاهات.`,
            image: "/img/insurers-feat-2.png"
        },
        {
            tag: "ميزة أساسية",
            title: "التتبع المباشر",
            description: "مراقبة الأداء والحوادث فور حدوثها.",
            image: "/img/insurers-feat-3.png"
        },
        {
            tag: "ميزة أساسية",
            title: "الاكتتاب القائم على البيانات",
            description: 
                "قم بإبلاغ قرارات الاكتتاب برؤى دقيقة تعتمد على البيانات.",
            image: "/img/insurers-feat-4.png"
        }
    ],
    cta: {
        tag: "تواصل معنا",
        title: "جاهز لتحسين عمليات أسطولك؟",
        description: 
            `اكتشف كيف يمكن لحلول التيليماتية المدعومة بالذكاء الاصطناعي 
            الخاصة بنا أن تعمل على تمكين عمليات الاكتتاب والمطالبات لديك بدقة.`,
        buttons: [
            {
                text: "احجز عرضًا توضيحيًا",
                color: "green",
                path: "/contact-us"
            }
        ],
        image: "/img/cta-img.png"
    }
};
