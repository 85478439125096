export const footerData = {
    newsletter: {
        title: "ابق على اطلاع",
        description: "سنرسل لك رسالة إخبارية منسقة بعناية كل أسبوع. لا بريد غير هام، فقط رؤى قيمة.",
        buttonText: "البدء",
        termsText: `بالنقر على "البدء"، فإنك توافق على`,
        termsTextUrl: "الشروط و الأحكام",
        termsUrl: "/terms-conditions"
    },
    columns: [
        {
            title: "الحلول",
            links: ["أسطول", "التأمين", "الصناعة", "تطبيق السائق"],
            paths: ["/fleet", "/insurers", "/industries", "/drivers-app"]
        },
        {
            title: "عن الشركة",
            links: ["المسؤولية الاجتماعية للشركات", "اتصل بنا", "الدعم"],
            paths: ["/", "/contact-us", "/support"]
        },
        {
            title: "بوابة العميل",
            links: ["سياسة الخصوصية", "الشروط والأحكام"],
            paths: ["/privacy-policy", "/terms-conditions"]
        }
    ],
    logo: {
        url: "/",
        image: "/img/logo-small.svg"
    },
    copyright: "© 2024 رؤية. جميع الحقوق محفوظة",
    socialLinks: [
        {
            url: "https://x.com/rooya_ai",
            icon: "/img/twitter.svg"
        },
        {
            url: "https://www.instagram.com/rooya_ai_telematics",
            icon: "/img/instagram.svg"
        },
        {
            url: "https://www.linkedin.com/company/rooya",
            icon: "/img/linkedin.svg"
        }
    ]
};
