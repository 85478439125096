import React, { Suspense } from "react";
import { Button } from "../components/Button";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import "./../styles/DriversApp.css";
import "./../styles/Typography.css";
import Divider from "../components/Divider";
import heroImage from '../images/Hand.svg';
import playStoreImage from '../images/Mobile app store badge-1.svg';
import appStoreImage from '../images/Mobile app store badge.svg';
import featureIcon1 from '../images/Devices-1.svg';
import featureIcon2 from '../images/Devices.svg';
import featureIcon3 from '../images/Devices-2.svg';
import featureIcon4 from '../images/Devices-3.svg';
import additionalFeatureImage1 from '../images/PD.svg';
import cardImage1 from '../images/image.svg';
import cardImage2 from '../images/image-1.svg';
import cardImage3 from '../images/image-2.svg';
import cardImage4 from '../images/image-3.svg';
import cardImage5 from '../images/image-4.svg';
import cardImage6 from '../images/image-5.svg';
import ctaImage from '../images/Graphics.svg';

const FeaturesSlider = React.lazy(() => import("../components/FeaturesSlider"));

const DriversApp = () => {
    const { t, i18n } = useTranslation(); // Use the translation hook

    // Access the translated data
    const hero = t('DriversAppData.hero', { returnObjects: true });
    const features = t('DriversAppData.features', { returnObjects: true });
    const cards = t('DriversAppData.cards', { returnObjects: true });
    const additionalFeatures = t('DriversAppData.additionalFeatures', { returnObjects: true });
    const cta = t('DriversAppData.cta', { returnObjects: true });

    return (
        <>
            <div className='DriversApp-hero'>
                <div className="DriversApp-banner-container">
                    <div className="DriversApp-banner">
                        <h1 className="DriversApp-category-header button-m-semibold rooya-text-gradient">
                            {i18n.language === 'ar' ? 'تطبيق السائق' : 'Driver\'s App'}
                        </h1>
                        <h2 className="DriversApp-banner-title h5-semibold">
                            {hero.title}
                        </h2>
                        <p className="DriversApp-banner-description body-m-regular">
                            {hero.description}
                        </p>
                        <Button color='green' path={hero.ctaButton.path}>
                            <span className='button-m-semibold'>{hero.ctaButton.text}</span>
                        </Button>
                        <Divider height='40px' />
                        <div className="DriversApp-image-wrapper">
                            <img 
                                className='DriversApp-hero-image' 
                                src={heroImage} 
                                alt={i18n.language === 'ar' ? 'إدارة تطبيق السائق' : 'DriversApp management'} 
                                loading="lazy"
                            />
                            <div className='DriversApp-buttons'>
                                <a href={hero.appStoreButton.link} target="_blank" rel="noopener noreferrer">
                                    <img src={appStoreImage} alt={hero.appStoreButton.alt} loading="lazy" />
                                </a>
                                <a href={hero.playStoreButton.link} target="_blank" rel="noopener noreferrer">
                                    <img src={playStoreImage} alt={hero.playStoreButton.alt} loading="lazy" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='DriversApp-cards-section'>
                <div className='DriversApp-banner-container'>
                    <h1 className="DriversApp-category-header button-m-semibold rooya-text-gradient">
                        {features.headerTitle}
                    </h1>
                    <h2 className="TestDriverApp-banner-description h6-semibold">
                        {features.headerDescription}
                    </h2>
                </div>
                <div className='DriversApp-cards-grid'>
                    {cards.items.map((card, index) => (
                        <div key={index} className='DriversApp-card-item'>
                            <img 
                                src={
                                    index === 0 ? cardImage1 : 
                                    index === 1 ? cardImage2 : 
                                    index === 2 ? cardImage3 :
                                    index === 3 ? cardImage4 :
                                    index === 4 ? cardImage5 :
                                    cardImage6
                                } 
                                alt={card.title} 
                                className='DriversApp-card-image' 
                                loading="lazy"
                            />
                            <h2 className='DriversApp-card-title h8-semibold'>{card.title}</h2>
                            <p className='DriversApp-card-description body-s-regular'>{card.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className='DriversApp-features'>
                <div className="DriversApp-banner-container">
                    <h1 className="DriversApp-category-header button-m-semibold rooya-text-gradient">
                        {i18n.language === 'ar' ? 'إطلاق الإمكانات الكاملة لأسطولك' : 'Unlock the Full Potential of Your Fleet'}
                    </h1>
                    <h2 className="TestDriverApp-banner-description h6-semibold">
                        {i18n.language === 'ar' ? 'قيادة النجاح باستخدام حلول متقدمة' : 'Driving Success with Cutting-Edge Solutions'}
                    </h2>
                </div>
                <div className='DriversApp-features-container'>
                    {features.items.map((feature, index) => (
                        <div key={index} className='DriversApp-feature'>
                            <img 
                                src={
                                    index === 0 ? featureIcon1 : 
                                    index === 1 ? featureIcon2 : 
                                    index === 2 ? featureIcon3 :
                                    featureIcon4
                                } 
                                alt={feature.title} 
                                className='DriversApp-feature-icon' 
                                loading="lazy"
                            />
                            <h2 className='DriversApp-feature-title h6-semibold'>{feature.title}</h2>
                            <p className='DriversApp-feature-description body-s-regular'>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            <Suspense fallback={<div>Loading...</div>}>
                <FeaturesSlider additionalFeatures={additionalFeatures.map((feature, index) => ({
                    ...feature,
                    image: additionalFeatureImage1 
                }))} />
            </Suspense>
            
            <div className='DriversApp-cta-wrapper'>
                <div className='DriversApp-cta-container'>
                    <div className='DriversApp-cta'>
                        <span className='DriversApp-cta-tag button-s-semibold'>{cta.tag}</span>
                        <h2 className='DriversApp-cta-title h5-semibold'>{cta.title}</h2>
                        <p className='DriversApp-cta-description body-m-regular'>{cta.description}</p>
                        <div className='DriversApp-cta-buttons'>
                            <a href={cta.buttons[0].link} target="_blank" rel="noopener noreferrer">
                                <img src={appStoreImage} alt={cta.buttons[0].alt} loading="lazy" />
                            </a>
                            <a href={cta.buttons[1].link} target="_blank" rel="noopener noreferrer">
                                <img src={playStoreImage} alt={cta.buttons[1].alt} loading="lazy" />
                            </a>
                        </div>
                    </div>
                    <div className='DriversApp-cta-image'>
                        <img src={ctaImage} alt={i18n.language === 'ar' ? 'إدارة تطبيق السائق' : 'DriversApp management'} loading="lazy" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default DriversApp;