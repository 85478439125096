import React from "react";
import "./../styles/Typography.css";
import "./../styles/TermsConditions.css";
import Divider from "../components/Divider";

const TermsConditions = () => {
    return (
        <>
            <div className='fleet-hero' style={{ textAlign: "center", padding: "5rem", backgroundColor: "#292828", color: "white" }}>
                <p className='body-m-regular' style={{ padding: "1rem", color: "#726d6d" }}>Current as of 20 Jan 2024</p>
                <h1 className='h5-semibold'>Terms and conditions</h1>
            </div>
            <Divider height="50px" />
            <div className="div-terms-conditions">
                <h3 className="h3-terms-conditions">1. ABOUT OUR TERMS</h3>
                <p className="p-terms-conditions">1.1 These Terms explain how you may use this website (the "Site") which is provided by us free of charge.</p>
                <p className="p-terms-conditions">1.2 References in these Terms to the Site include the following websites: www.polysurance.com, and all associated web pages.</p>
                <p className="p-terms-conditions">1.3 You should read these Terms carefully before using the Site.</p>
                <p className="p-terms-conditions">1.4 By accessing or using the Site or otherwise indicating your consent, you agree to be bound by these Terms and the documents referred to in them.</p>
                <p className="p-terms-conditions">1.5 If you do not agree with or accept any of these Terms, you should stop using the Site immediately.</p>
                <p className="p-terms-conditions">1.6 If you have any questions about the Site, please contact us by email: contact@polysurance.com</p>
                <p className="p-terms-conditions">1.7 Definitions</p>
                <p className="p-terms-conditions"><b>"Content"</b> means any text, images, video, audio or other multimedia content, software or other information or material submitted to or on the Site;</p>
                <p className="p-terms-conditions"><b>"Site"</b> has the meaning given to it in clause 1.1 and 1.2;</p>
                <p className="p-terms-conditions"><b>"Terms"</b> means these terms and conditions of use as updated from time to time under clause 11;</p>
                <p className="p-terms-conditions"><b>"Unwanted Submission"</b> has the meaning given to it in clause 5.1;</p>
                <p className="p-terms-conditions"><b>"we"</b> means Polysurance Limited, registered in England and Wales with company registration number 12456074, (and "us" or "our" shall have the same meaning); and</p>
                <p className="p-terms-conditions"><b>"you"</b> means the person or legal person accessing or using the Site or its Content (and "your" shall have the same meaning).</p>
            </div>
            <div className="div-terms-conditions">
                <h3 className="h3-terms-conditions">2. USING THE SITE</h3>
                <p className="p-terms-conditions">2.1 The Site is for your use only. You agree that you are solely responsible for:</p>
                <p className="p-terms-conditions">2.1.1 all costs and expenses you may incur in relation to your use of the Site; and</p>
                <p className="p-terms-conditions">2.1.2 keeping your password and other account details confidential.</p>
                <p className="p-terms-conditions">2.2 The Site is intended for use only by those who can access it from within the UK. If you choose to access the Site from locations outside the UK, you are responsible for compliance with local laws where they are applicable.</p>
                <p className="p-terms-conditions">2.3 We seek to make the Site as accessible as possible. If you have any difficulties using the Site, please contact us at <b>contact@rooya.io</b>.</p>
                <p className="p-terms-conditions">2.4 You must not use the Site:</p>
                <p className="p-terms-conditions">2.4.1 in any way that causes, or may cause, damage to the Site or impairment of the availability or accessibility of the Site;</p>
                <p className="p-terms-conditions">2.4.2 in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity;</p>
                <p className="p-terms-conditions">2.4.3 to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, worm, Trojan horse, or other malicious computer software.</p>
                <p className="p-terms-conditions">2.5 You must not access without our permission, interfere with, damage or disrupt any part of the Site, any equipment or network on which the Site is stored, any software used in the provision of the Site or any equipment, network or software owned or used by any third party.</p>
            </div>
            <div className="div-terms-conditions">
                <h3 className="h3-terms-conditions">3. YOUR PRIVACY AND PERSONAL INFORMATION</h3>
                <p className="p-terms-conditions">3.1 Your privacy and personal information are important to us. Any personal information that you provide to us will be dealt with in line with our privacy policy, which explains what personal information we collect from you, how and why we collect, store, use and share such information, your rights in relation to your personal information and how to contact us and supervisory authorities in the event you have a query or complaint about the use of your personal information.</p>
                <p className="p-terms-conditions">3.2 Our privacy policy is available <a href="privacy-policy">here.</a></p>
            </div>
            <div className="div-terms-conditions">
                <h3 className="h3-terms-conditions">4. OWNERSHIP, USE AND INTELECTUAL PROPERTY RIGHTS</h3>
                <p className="p-terms-conditions">4.1 The Site and all intellectual property rights in it including but not limited to any Content are owned by us, our licensors or both (as applicable). Intellectual property rights means rights such as: copyright, trade marks, domain names, design rights, database rights, patents and all other intellectual property rights of any kind whether or not they are registered or unregistered (anywhere in the world). We and our licensors reserve all of our and their rights in any intellectual property in connection with these Terms. This means, for example, that we and they remain owners of them and free to use them as we and they see fit.</p>
                <p className="p-terms-conditions">4.2 Nothing in these Terms grants you any legal rights in the Site other than as necessary to enable you to access the Site. You agree not to adjust to try to circumvent or delete any notices contained on the Site (including any intellectual property notices) and in particular in any digital rights or other security technology embedded or contained within the Site.</p>
            </div>
            <div className="div-terms-conditions">
                <h3 className="h3-terms-conditions">5. SUBMITTING INFORMATION TO THE SITE</h3>
                <p className="p-terms-conditions">5.1 While we try to make sure that the Site is secure, we cannot guarantee the security of any information that you supply to us and therefore we cannot guarantee that it will be kept confidential. For that reason, you should not let us have any patentable ideas or patent applications, advertising or marketing suggestions, prototypes, or any other information that you regard as confidential, commercially sensitive or valuable ("Unwanted Submissions"). While we value your feedback, you agree not to submit any Unwanted Submissions.</p>
                <p className="p-terms-conditions">5.2 We may use any Unwanted Submissions as we see reasonably fit on a free-of-charge basis (bear in mind that we have no way of knowing whether such information is confidential, commercially sensitive or valuable because we do not monitor the Site to check for these matters). Therefore, we will not be legally responsible for keeping any Unwanted Submissions confidential nor will we be legally responsible to you or anybody else for any use of such Unwanted Submissions.</p>
            </div>
            <div className="div-terms-conditions">
                <h3 className="h3-terms-conditions">6. ACCURACY OF INFORMATION AND AVAILABILITY OF THE SITE</h3>
                <p className="p-terms-conditions">6.1 While we try to make sure that the Site is accurate, up-to-date and free from bugs, we cannot promise that it will be. Furthermore, we cannot promise that the Site will be fit or suitable for any purpose. Any reliance that you may place on the information on the Site is at your own risk.</p>
                <p className="p-terms-conditions">6.2 We may suspend or terminate operation of the Site at any time as we see fit.</p>
                <p className="p-terms-conditions">6.3 If you are a consumer, you may have certain legal rights when using the Site. These are also known as ‘statutory rights’ as they are derived from laws such as the Consumer Rights Act 2015.</p>
                <p className="p-terms-conditions">6.4 If you are a business user of the Site, we exclude all implied conditions, warranties, representations or other terms that may apply to our Site or any content on it.</p>
                <p className="p-terms-conditions">6.5 Content is provided for your general information purposes only and to inform you about us and our products and news, features, services and other websites that may be of interest. It does not constitute technical, financial or legal advice or any other type of advice and should not be relied on for any purposes.</p>
                <p className="p-terms-conditions">6.6 While we try to make sure that the Site is available for your use, we do not promise that the Site is available at all times nor do we promise the uninterrupted use by you of the Site.</p>
            </div>
            <div className="div-terms-conditions">
                <h3 className="h3-terms-conditions">7. HYPERLINKS</h3>
                <p className="p-terms-conditions">7.1 The Site may contain hyperlinks or references to third party websites other than the Site. Any such hyperlinks or references are provided for your convenience only. We have no control over third party websites and accept no legal responsibility for any content, material or information contained in them. The display of any hyperlink and reference to any third party website does not mean that we endorse that third party's website, products or services. Your use of a third party site may be governed by the terms and conditions of that third party site.</p>
                <p className="p-terms-conditions">7.2 You may link to the home page of our Site, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</p>
                <p className="p-terms-conditions">7.3 You must not establish a link in such a way so as to suggest any form of association, approval or endorsement on out part where none exists.</p>
                <p className="p-terms-conditions">7.4 We reserve the right to withdraw linking permission without notice.</p>
                <p className="p-terms-conditions">7.5 If you wish to link to or make any use of content on our site other than that set out above, please contact us at <b>contact@rooya.io</b>.</p>
            </div>
            <div className="div-terms-conditions">
                <h3 className="h3-terms-conditions">8. LIMITATION ON OUR LIABILITY</h3>
                <p className="p-terms-conditions">8.1 Except for any legal responsibility that we cannot exclude in law (such as for death or personal injury) or arising under applicable laws relating to the protection of your personal information, we are not legally responsible for any:</p>
                <p className="p-terms-conditions">8.1.1 losses that:</p>
                <p className="p-terms-conditions">(a) were not foreseeable to you and us when these Terms were formed; or</p>
                <p className="p-terms-conditions">(b) that were not caused by any breach on our part;</p>
                <p className="p-terms-conditions">8.1.2 business losses; and</p>
                <p className="p-terms-conditions">8.1.3 losses to non-consumers.</p>
            </div>
            <div className="div-terms-conditions">
                <h3 className="h3-terms-conditions">9. EVENTS BEYOND OUR CONTROL</h3>
                <p className="p-terms-conditions">We shall have no liability to you for any breach of these Terms caused by any event or circumstance beyond our reasonable control including, but not limited to, strikes, lock-outs or other industrial disputes; breakdown of systems or network access; or flood, fire, explosion or accident.</p>
            </div>
            <div className="div-terms-conditions">
                <h3 className="h3-terms-conditions">10. RIGHTS OF THIRD PARTIES</h3>
                <p className="p-terms-conditions">No one other than a party to these Terms has any right to enforce any of these Terms.</p>
            </div>
            <div className="div-terms-conditions">
                <h3 className="h3-terms-conditions">11. VARIATION</h3>
                <p className="p-terms-conditions">These Terms are dated 9 December 2020. No changes to these Terms are valid or have any effect unless agreed by us in writing or made in accordance with this clause 12. We reserve the right to vary these Terms from time to time. Our updated Terms will be displayed on the Site and by continuing to use and access the Site following such changes, you agree to be bound by any variation made by us. It is your responsibility to check these Terms from time to time to verify such variations.</p>
            </div>
            <div className="div-terms-conditions">
                <h3 className="h3-terms-conditions">12. DISPUTES</h3>
                <p className="p-terms-conditions">12.1 We will try to resolve any disputes with you quickly and efficiently.</p>
                <p className="p-terms-conditions">12.2 If you are unhappy with us please contact us as soon as possible.</p>
                <p className="p-terms-conditions">12.3 If you and we cannot resolve a dispute using our complaint handling procedure, we will:</p>
                <p className="p-terms-conditions">12.3.1 let you know that we cannot settle the dispute with you; and</p>
                <p className="p-terms-conditions">12.3.2 give you certain information about our alternative dispute resolution provider.</p>
                <p className="p-terms-conditions">12.4 If you want to take court proceedings, the relevant courts of England and Wales will have exclusive jurisdiction in relation to these Terms.</p>
                <p className="p-terms-conditions">12.5 The laws of England and Wales will apply to these Terms.</p>
            </div>
        </>
    );
}

export default TermsConditions;