import React, { useState, useEffect } from "react";
import "./../styles/FeatureSlider.css";

export default function FeaturesSlider({ additionalFeatures }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 744);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % additionalFeatures.length);
        }, 3000);
        return () => clearInterval(interval);
    }, [additionalFeatures.length]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 744);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className='additional-features-slider'>
            <div className='additional-feature-container'>
                <div className='additional-features-list'>
                    {additionalFeatures.map((feature, index) => (
                        <div className='additional-feature-item-container' key={index}>
                            <div
                                className={`additional-feature-item ${index === activeIndex ? "active" : ""}`}
                                onClick={() => setActiveIndex(index)}
                            >
                                <h3 className='additional-feature-title'>{feature.title}</h3>
                                <p className='additional-feature-description'>{feature.description}</p>
                            </div>
                            {isMobile && index === activeIndex && (
                                <div className='additional-features-image mobile'>
                                    {feature.image ? (
                                        <img src={feature.image} alt={feature.title} />
                                    ) : (
                                        <div className='image-placeholder'>
                                            <p>here will be some nice graphics later :)</p>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                {!isMobile && (
                    <div className='additional-features-image desktop'>
                        {additionalFeatures[activeIndex].image ? (
                            <img
                                src={additionalFeatures[activeIndex].image}
                                alt={additionalFeatures[activeIndex].title}
                            />
                        ) : (
                            <div className='image-placeholder'>
                                <p>here will be some nice graphics later :)</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
