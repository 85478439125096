// fleetData.js

export const fleetData = {
    hero: {
        title: "Transform your fleet management with Rooya AI-telematics",
        description:
            "Our advanced solutions are designed to optimise every aspect of fleet operations, from real-time monitoring to detailed performance analysis",
        ctaButton: {
            text: "How it works?",
            path: "/technology"
        },
        image: "/img/hero-img.png"
    },
    features: [
        {
            title: "Real-time vehicle tracking",
            description:
                "Keep an eye on our fleet's location, speed, and status with live updates, ensuring efficient router planning and timely deliveries",
            icon: "/img/record.svg"
        },
        {
            title: "Performance insights",
            description:
                "Access customisable dashboards and detailed reports to monitor fleet performance, identify trends, and make informed decisions",
            icon: "/img/chart.svg"
        },
        {
            title: "Enhanced driver safety",
            description:
                "Improve driver behaviour with real-time alerts and feedback on unsafe practices, helping to reduce accidents and promote safer driving",
            icon: "/img/guard.svg"
        },
        {
            title: "Seamless integration",
            description:
                "Our solutions integrate effortlessly with existing fleet management systems, providing a smooth transition and minimal disruption",
            icon: "/img/refresh.svg"
        }
    ],
    additionalFeatures: [
        {
            tag: "Core feature",
            title: "High-definition dashcams",
            description: "Capture critical driving data with dual HD cameras",
            image: "/img/fleet-feat-1.png"
        },
        {
            tag: "Core feature",
            title: "AI-driven analysis",
            description: "Utilise advanced AI to monitor and analyse driver behaviour",
            image: "/img/fleet-feat-2.png"
        },
        {
            tag: "Core feature",
            title: "Custom alerts",
            description: "Receive real-time notifications for events and incidents",
            image: "/img/fleet-feat-3.png"
        },
        {
            tag: "Core feature",
            title: "Secure cloud storage",
            description: "Store and access data securely with our cloud-based system",
            image: "/img/fleet-feat-4.png"
        }
    ],
    cta: {
        tag: "Contact us",
        title: "Ready to optimise your fleet operations?",
        description:
            "See how real-time insights and advanced analytics can transform the way you manage your fleet. Let’s drive your operations to new heights.",
        buttons: [
            {
                text: "Book a demo",
                color: "green",
                path: "/contact-us"
            }
        ],
        image: "/img/cta-img.png"
    }
};

export const insurersData = {
    hero: {
        title: "Optimise risk management with Rooya AI-telematics",
        description:
            "Our advanced technology offers comprehensive insights and data-driven solutions to support your insurance operations",
        ctaButton: {
            text: "How it works?",
            path: "/technology"
        },
        image: "/img/Hero-img2.svg"
    },
    features: [
        {
            title: "Claims reduction",
            description:
                "Implement technology designed to prevent accidents, which helps lower the frequency and severity of insurance claims",
            icon: "/img/key-feat-1.svg"
        },
        {
            title: "Accurate risk profiling",
            description:
                "Utilise detailed driver and vehicle data to assess risks more precisely and make informed underwriting decisions",
            icon: "/img/key-feat-2.svg"
        },
        {
            title: "Underwriting support",
            description:
                "Use comprehensive performance data to refine your underwriting processes and better assess potential risks",
            icon: "/img/key-feat-3.svg"
        },
        {
            title: "Data-driven insights",
            description:
                "Access in-depth reports and historical data to gain a clearer understanding of risk factors and improve your risk management strategies",
            icon: "/img/key-feat-4.svg"
        }
    ],
    additionalFeatures: [
        {
            tag: "Core feature",
            title: "Comprehensive risk reporting",
            description: "Detailed analysis of driver and vehicle performance.",
            image: "/img/insurers-feat-1.png"
        },
        {
            tag: "Core feature",
            title: "Historical data access",
            description: "Secure access to long-term data for trend analysis.",
            image: "/img/insurers-feat-2.png"
        },
        {
            tag: "Core feature",
            title: "Real-time tracking",
            description: "Monitor performance and incidents as they occur.",
            image: "/img/insurers-feat-3.png"
        },
        {
            tag: "Core feature",
            title: "Data-driven underwriting",
            description: "Inform underwriting decisions with accurate, data-driven insights.",
            image: "/img/insurers-feat-4.png"
        }
    ],
    cta: {
        tag: "Contact us",
        title: "Ready to optimise your fleet operations?",
        description:
            "Find out how our AI-telematics solutions can empower your underwriting and claims processes with data-driven precision",
        buttons: [
            {
                text: "Book a demo",
                color: "green",
                path: "/contact-us"
            }
        ],
        image: "/img/cta-img.png"
    }
};
