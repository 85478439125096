import React from "react";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook
import "../styles/Footer.css";

const Footer = () => {
    const { t } = useTranslation(); // Initialize the translation hook

    // Fetch translated data using the t function
    const footerData = t('footerData', { returnObjects: true });

    return (
        <footer className='footer-container'>
            <div className='footer-content'>
                <div className='footer-newsletter'>
                    <h2 className='footer-title h8-semibold'>{footerData.newsletter.title}</h2>
                    <p className='footer-description body-s-regular'>{footerData.newsletter.description}</p>
                    <form className='footer-form'>
                        <input type='email' placeholder={footerData.newsletter.placeholder} className='footer-input body-s-regular' />
                        <button type='submit' className='footer-button button-m-semibold'>
                            {footerData.newsletter.buttonText}
                        </button>
                    </form>
                    <p className='footer-terms body-xs-regular'>
                        {footerData.newsletter.termsText}
                        <a href={footerData.newsletter.termsUrl} className='footer-terms-link body-xs-medium'>
                            {footerData.newsletter.termsTextUrl}
                        </a>
                    </p>
                </div>
                <div className='footer-columns'>
                    {footerData.columns.map((column, index) => (
                        <div key={index} className='footer-column'>
                            <h3 className='footer-column-title body-s-regular'>{column.title}</h3>
                            <ul className='footer-list'>
                                {column.links.map((link, linkIndex) => (
                                    <li key={linkIndex} className='footer-list-item body-s-medium'>
                                        <a href={column.paths[linkIndex]} className='footer-link'>
                                            {link}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
            <div className='footer-bottom'>
                <div className='footer-logo-copyright'>
                    <span className='footer-logo'>
                        <img src={footerData.logo.image} alt='Rooya' />
                    </span>
                    <span className='footer-copyright body-s-regular'>{footerData.copyright}</span>
                </div>
                <div className='footer-social'>
                    {footerData.socialLinks.map((social, index) => (
                        <a
                            key={index}
                            href={social.url}
                            className='footer-social-link'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <div className={`footer-icon footer-icon-${social.url.toLowerCase()}`}>
                                <img src={social.icon} alt={social.url} />
                            </div>
                        </a>
                    ))}
                </div>
                <span className='footer-copyright-2 body-s-regular'>{footerData.copyright}</span>
            </div>
        </footer>
    );
};

export default Footer;
