import React from "react";
import { Button } from "../components/Button";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook
import "./../styles/Fleet.css";
import "./../styles/Typography.css";
import Divider from "../components/Divider";
import FeaturesSlider from "../components/FeaturesSlider";

const Fleet = () => {
    const { t } = useTranslation(); // Use the translation hook

    // Access the translated data
    const hero = t('fleetData.hero', { returnObjects: true });
    const additionalFeatures = t('fleetData.additionalFeatures', { returnObjects: true });
    const cta = t('fleetData.cta', { returnObjects: true });
    const features = t('fleetData.features', { returnObjects: true });

    return (
        <>
            <div className='fleet-hero'>
                <h1 className='fleet-hero-title h5-semibold'>{hero.title}</h1>
                <p className='fleet-hero-description body-m-regular'>{hero.description}</p>
                <Button color='green' path={hero.ctaButton.path}>
                    <span className='button-m-semibold'>{hero.ctaButton.text}</span>
                </Button>
                <Divider height='40px' />
                <img className='fleet-hero-image' src={hero.image} alt='fleet management' />
            </div>

            <div className='fleet-features'>
                <div className='fleet-features-container'>
                    {features.map((feature, index) => (
                        <div key={index} className='fleet-feature'>
                            <span className='fleet-key-feature-tag button-s-semibold'>
                                {t('fleetData.keyFeatureTag', 'Key feature')}
                            </span>
                            <img src={feature.icon} alt={feature.title} className='fleet-feature-icon' />
                            <h2 className='fleet-feature-title card-h6-semibold'>{feature.title}</h2>
                            <p className='fleet-feature-description body-s-regular'>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>

            <FeaturesSlider additionalFeatures={additionalFeatures} />

            <div className='fleet-cta-wrapper'>
                <div className='fleet-cta-container'>
                    <div className='fleet-cta'>
                        <span className='fleet-cta-tag button-s-semibold'>{cta.tag}</span>
                        <h2 className='fleet-cta-title h5-semibold'>{cta.title}</h2>
                        <p className='fleet-cta-description body-m-regular'>{cta.description}</p>
                        <div className='fleet-cta-buttons'>
                            {cta.buttons.map((button, index) => (
                                <Button key={index} color={button.color} path={button.path} style={{ width: "100%" }}>
                                    <span className='button-m-semibold'>{button.text}</span>
                                </Button>
                            ))}
                        </div>
                    </div>
                    <div className='fleet-cta-image'>
                        <img src={cta.image} alt='fleet management' />
                    </div>
                </div>
            </div> 
        </>
    );
};

export default Fleet;
