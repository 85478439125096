export const DriversAppData = {
    hero: {
        title: "Designed to empower drivers with essential tools and insights",
        description:
            "It provides real-time data, performance metrics, and personalised feedback, making it an indispensable tool for modern fleet drivers",
        ctaButton: {
            text: "Book a demo?",
            path: "/contact-us"
        },
        image: "./../assets/Hand.svg",
        playStoreButton: {
            svg: "./../assets/Mobile app store badge-1.svg",
            alt: "Get it on Google Play",
            link: "https://apps.apple.com/gb/app/polydrive/id6480055464"
        },
        appStoreButton: {
            svg: "./../assets/Mobile app store badge.svg",
            alt: "Download on the App Store",
            link: "https://play.google.com/store/apps/details?id=com.polydrive&hl=en_GB"
        }
    },
    features: {
        headerTitle: "Essential Tools for Every Driver",
        headerDescription: "Enhancing Driver Performance with Precision",
        items: [
            {
                title: "Improved driver behaviour insights",
                description:
                    "Continuous monitoring and personalized feedback foster safer and more efficient driving habits.",
                icon: "./../assets/Devices-1.svg"
            },
            {
                title: "Enhanced safety",
                description:
                    "Real-time feedback and alerts help drivers avoid risky behaviors, contributing to safer roads.",
                icon: "./../assets/Devices.svg"
            },
            {
                title: "Efficiency boost",
                description:
                    "Leverage comprehensive performance data to refine underwriting processes and better assess potential risks.",
                icon: "./../assets/Devices-2.svg"
            },
            {
                title: "Better communication",
                description:
                    "Facilitate seamless communication between drivers and fleet managers, ensuring timely updates and coordinated efforts.",
                icon: "./../assets/Devices-3.svg"
            }
        ]
    },
    additionalFeatures: [
        {
            title: "Quick Installation",
            description: "Download the app on your smartphone, log in with your credentials, and get started instantly.",
            image: "./../assets/PD.svg"
        },
        {
            title: "AI-Driven Performance Analysis",
            description: "Utilize AI to gain deep insights into driving patterns, helping drivers and managers make informed decisions.",
            image: "./../assets/PD.svg"
        },
        {
            title: "Review, Adjust, Improve",
            description: "After each trip, review performance scores and trip history. Use these insights to make adjustments and continuously improve driving behavior.",
            image: "./../assets/PD.svg"
        },
    ],
    cards: {
        items: [
            {
                title: "Comprehensive Driver Monitoring",
                image: "./../assets/image.svg",
                description: "Track safe driving behaviors with detailed scores and visual analytics. Review trips, incidents, and safety rankings to promote adherence to best driving practices."
            },
            {
                title: "Instantaneous Alerts",
                image: "./../assets/image-1.svg",
                description: "Receive immediate notifications for critical events like speeding, harsh braking, and lane departures. Customizable alerts empower drivers to focus on specific improvement areas."
            },
            {
                title: "In-Depth Trip History",
                image: "./../assets/image-2.svg",
                description: "Access detailed logs of each journey, including start and end times, routes, and incident reports. Use these insights to identify patterns and optimize overall performance."
            },
            {
                title: "Global Reach with Multilingual Support",
                image: "./../assets/image-3.svg",
                description: "Available in multiple languages including English, Arabic, Urdu, Bengali, and Hindi to serve diverse driver communities effectively."
            },
            {
                title: "Daily reports",
                image: "./../assets/image-4.svg",
                description: "Get personalized daily summaries of driving performance and key metrics. Focus on continuous improvement with actionable insights delivered directly to your device."
            },
            {
                title: "Easy installation and use",
                image: "./../assets/image-5.svg",
                description: "Intuitive interface designed for ease of use, encouraging drivers to engage with the app and improve their driving habits."
            }
        ]
    },
    cta: {
        tag: "Contact us",
        title: "Ready to optimise your fleet's operations?",
        description:
            "Enhance driving performance, ensure safety, and streamline your operations with our cutting-edge technology.",
        buttons: [
            {
                svg: "./../assets/Mobile app store badge.svg",
                alt: "Download on the App Store",
                link: "https://play.google.com/store/apps/details?id=com.polydrive&hl=en_GB"
            },
            {
                svg: "./../assets/Mobile app store badge-1.svg",
                alt: "Get it on Google Play",
                link: "https://apps.apple.com/gb/app/polydrive/id6480055464"
            }
        ],
        image: "./../assets/Graphics.svg"
    }
}
