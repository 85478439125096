export const contactUsData = {
  banner: {
    header: "اتصل بنا",
    title: `تواصل معنا لتعرف على تقنية رؤية المدعومة بالذكاء الاصطناعي`,
    description:
      `نحن هنا لمساعدتك في تحسين عمليات الأسطول وتعزيز السلامة. 
       تواصل معنا لمزيد من المعلومات، لطلب عرض توضيحي، أو للتحدث مع فريقنا`,
    contact: [
      {
        title: "رقم الهاتف",
        description: "+966 11 425 5665",
        icon: "/img/map-pin.svg",
        colour: "#0DAB724D"
      },
      {
        title: "البريد الالكتروني",
        description: "support@rooya.ai",
        icon: "/img/e-mail.svg",
        colour: "#FDB12D33"
      },
      {
        title: "نسعد بخدمتكم في الأوقات التالية",
        description: "من الأحد إلى الخميس، من الساعة 9 صباحا حتى 6 مساءً",
        icon: "/img/clock.svg",
        colour: "#F1585333"
      }
    ]
  },
  
  talk: {
    info: {
      title: "نحن هنا للمساعدة",
      description: `نحن هنا لمساعدتك في تحسين عمليات الأسطول وتعزيز السلامة اسطول`,
      listDescription: "تواصل معنا للمزيد من المعلومات أو:",
      listItems: [
        "اطلب عرض توضيحي",
        "تواصل مع فريق الدعم"
      ],
      listImg: "/img/green-tick.svg",
      contactDetails: [
        {
          label: "البريد الالكتروني",
          value: "contact@rooya.ai"
        },
        {
          label: "رقم الهاتف",
          value: "+966 11 425 5665"
        },
        {
          label: "العنوان",
          value: "شارع التحلية، مجمع الدغيثر، العليا، الرياض، المملكة العربية السعودية"
        }
      ]
    },
    form: {
      name:{
        label: "الاسم*",
        placeholder: "ما هو اسمك؟",
        type: "input"
      },
      email:{
        label: "البريد الالكتروني*",
        placeholder: "ما هو الايميل الذي تود أن نتواصل معك عن طريقه؟",
        type: "input"
      },
      message:{
        label: "اكتب رسالتك*",
        placeholder: "كيف يمكننا خدمتك؟",
        type: "textarea"
      },
      checkbox: {
        label: "نعم, أريد تلقي اخر التحديثات عن طريق البريد الالكتروني",
        type: "checkbox"
      },
      submitButton: {
        text: "إرسال",
        type: "button"
      },
      tandc: {
        text: `بالضغط على "إرسال" فإنك تقر بالشروط و الأحكام`,
        tc: "Terms and Conditions"
      }
    }
  }
};
