export const IndustriesData = {
  banner: {
      header: "نقود الابتكار في الصناعات",
      title: "حلول مصممة خصيصًا لقطاعك",
      description: 
          `تلبي حلولنا المتقدمة مجموعة متنوعة من الصناعات، 
          ولكل منها احتياجات فريدة لإدارة الأسطول والسلامة والكفاءة.`,
  },
  categories: [
      {
          header: "اكتشف خبرتنا",
          name: "حلول خاصة بالصناعة",
          questions: [
              {
                  question: "اللوجستية والنقل",
                  icon: "/img/Icons.svg",
                  answer: {
                      text: 
                          `قم بتبسيط عملياتك اللوجستية من خلال تتبع المركبات بشكل مباشر وتحليلات الأداء. 
                          تساعد حلولنا على تحسين المسارات، وتقليل استهلاك الوقود، وتحسين كفاءة الأسطول بشكل عام، 
                          مما يضمن عمليات التسليم الموثوقة وفي الوقت المناسب.`,
                      bullets: [
                          "تخطيط فعال للطرق",
                          "مراقبة استهلاك الوقود",
                          "تعزيز موثوقية التسليم"
                      ]
                  }
              },
              {
                  question: "البناء",
                  icon: "/img/Icons-1.svg",
                  answer: {
                      text: 
                          `عزز السلامة والإنتاجية في مواقع البناء من خلال حلولنا القوية لتقنية المعلومات. 
                          مراقبة استخدام المعدات والمركبات، وتتبع عمليات موقع العمل، والتأكد من الامتثال للوائح السلامة.`,
                      bullets: [
                          "تتبع استخدام المعدات",
                          "مراقبة سلامة الموقع",
                          "الامتثال للوائح"
                      ]
                  }
              },
              {
                  question: "التسليم والتجارة الإلكترونية",
                  icon: "/img/Icons-2.svg",
                  answer: {
                      text: 
                          `ضمان عمليات تسليم سريعة وآمنة باستخدام أدوات إدارة الأسطول الشاملة. 
                          توفر تقنيتنا تتبعًا مباشراً ورؤى حول الأداء لمساعدتك في إدارة عمليات التسليم بفعالية 
                          والحفاظ على مستويات الخدمة العالية.`,
                      bullets: [
                          "تتبع التسليم بشكل مباشر",
                          "رؤى الأداء",
                          "إدارة مستوى الخدمة"
                      ]
                  }
              },
              {
                  question: "القطاع الحكومي",
                  icon: "/img/Icons-3.svg",
                  answer: {
                      text: 
                          `قم بتحسين كفاءة وسلامة أساطيل المركبات الحكومية من خلال حلول تكنولوجيا المعلومات المتقدمة لدينا. 
                          تدعم تقنيتنا إدارة الأسطول، وتضمن الامتثال، وتعزز الفعالية التشغيلية.`,
                      bullets: [
                          "دعم إدارة الأسطول",
                          "ضمان الامتثال",
                          "الفعالية التشغيلية"
                      ]
                  }
              },
              {
                  question: "النفط والبتروكيماويات والغاز",
                  icon: "/img/Icons-4.svg",
                  answer: {
                      text: 
                          `قم بمراقبة وإدارة أساطيلك المتخصصة في قطاعات النفط والبتروكيماويات والغاز بدقة. 
                          توفر حلولنا ميزات التتبع المباشر وإدارة المخاطر والسلامة المصممة خصيصًا للبيئات عالية المخاطر.`,
                      bullets: [
                          "المراقبة المباشرة للأسطول",
                          "إدارة المخاطر",
                          "تحسينات السلامة"
                      ]
                  }
              },
              {
                  question: "المأكولات والمشروبات",
                  icon: "/img/Icons-5.svg",
                  answer: {
                      text: 
                          `قم بتحسين توزيع الأطعمة والمشروبات لديك من خلال حلول إدارة الأسطول لدينا. 
                          ضمان التسليم في الوقت المناسب، والحفاظ على جودة المنتج، وتعزيز الكفاءة التشغيلية من خلال التكنولوجيا المتقدمة لدينا.`,
                      bullets: [
                          "التسليم في الوقت المناسب",
                          "الحفاظ على جودة المنتج",
                          "الكفاءة التشغيلية"
                      ]
                  }
              },
              {
                  question: "الصناعة",
                  icon: "/img/Icons-6.svg",
                  answer: {
                      text: 
                          `يمكنك تعزيز الإنتاجية والكفاءة التشغيلية في التصنيع من خلال تتبع المركبات والمعدات بشكل مباشر. 
                          تساعد حلولنا في تبسيط العمليات ومراقبة الاستخدام وضمان الامتثال لمعايير السلامة.`,
                      bullets: [
                          "تعزيز الإنتاجية",
                          "مراقبة الاستخدام",
                          "الامتثال للسلامة"
                      ]
                  }
              },
              {
                  question: "التوزيع بالتجزئة",
                  icon: "/img/Icons-7.svg",
                  answer: {
                      text: 
                          `تعزيز كفاءة التوزيع بالتجزئة من خلال حلول تكنولوجيا المعلومات لدينا. 
                          قم بإدارة أداء أسطولك وتحسين المسارات وضمان التسليم في الوقت المناسب لتلبية توقعات العملاء.`,
                      bullets: [
                          "إدارة أداء الأسطول",
                          "تحسين المسار",
                          "التسليم في الوقت المناسب"
                      ]
                  }
              },
              {
                  question: "النقل المصرفي والأمني",
                  icon: "/img/Icons-8.svg",
                  answer: {
                      text: 
                          `ضمان سلامة وأمن مركبات النقل المصرفية والأمنية من خلال تكنولوجيا المراقبة المتقدمة لدينا. 
                          توفر حلولنا تتبعًا شاملاً وإدارة المخاطر لحماية الأصول القيمة.`,
                      bullets: [
                          "سلامة وأمن المركبات",
                          "التتبع الشامل",
                          "إدارة المخاطر"
                      ]
                  }
              },
              {
                  question: "سيارات الأجرة ومشاركة الركاب",
                  icon: "/img/Icons-9.svg",
                  answer: {
                      text: 
                          `قم بتحسين سلامة وكفاءة خدمات سيارات الأجرة ومشاركة الرحلات من خلال حلول تكنولوجيا المعلومات لدينا. 
                          مراقبة سلوك السائق وتعزيز سلامة الركاب وتحسين إدارة الأسطول لتحسين جودة الخدمة.`,
                      bullets: [
                          "مراقبة سلوك السائق",
                          "تعزيز سلامة الركاب",
                          "تحسين إدارة الأسطول"
                      ]
                  }
              },
              {
                  question: "إدارة أسطول الشركات",
                  icon: "/img/Icons-10.svg",
                  answer: {
                      text: 
                          `قم بتبسيط إدارة أساطيل الشركات من خلال حلول تكنولوجيا المعلومات المتقدمة لدينا. 
                          احصل على رؤى مباشرة حول أداء الأسطول، وتحسين العمليات، وتحسين الكفاءة العامة.`,
                      bullets: [
                          "رؤى الأداء",
                          "التحسين التشغيلي",
                          "تحسينات الكفاءة"
                      ]
                  }
              }
          ]
      }
  ],
  cta: {
      tag: "تواصل معنا",
      title: "جاهز لتحسين عمليات أسطولك؟",
      description: 
          `أطلق العنان للإمكانات الكاملة لأسطولك من خلال حلولنا المتطورة المصممة 
          لتعزيز السلامة والكفاءة والامتثال في جميع الصناعات.`,
      buttons: [
          {
              text: "احجز عرضًا توضيحيًا",
              color: "green",
              path: "/contact-us"
          }
      ],
      image: "/img/cta-img.png"
  }
};
