// letsTalkData.js

export const letsTalkData = {
    talk: {
        info: {
            title: "Let's talk",
            description: "We are here to help you optimise your fleet operations and enhance safety!",
            listDescription: "Get in touch with us for more information or:",
            listItems: [
                "Request a demo",
                "Speak to our support team"
            ],
            listImg: "/img/white-tick.svg",
            contactDetails: [
                {
                    label: "Email",
                    value: "contact@rooya.ai"
                },
                {
                    label: "Phone number",
                    value: "+966 11 425 5665"
                },
                {
                    label: "Address",
                    value: "Riyadh-al-Olaya-Tahlia Street-Duaithar Compound, Kingdom of Saudi Arabia"
                }
            ]
        },
        form: {
            name: {
                label: "Your name*",
                placeholder: "What is your name?",
                type: "input"
            },
            email: {
                label: "Email address*",
                placeholder: "What is your contact email?",
                type: "input"
            },
            message: {
                label: "Write your message*",
                placeholder: "How can we assist you?",
                type: "textarea"
            },
            checkbox: {
                label: "Yes, I want to receive latest updates via the email",
                type: "checkbox"
            },
            submitButton: {
                text: "Send message",
                type: "button"
            },
            tandc: {
                text: "By clicking Send message you agree to the ",
                tc: "Terms and Conditions"
            }
        }
    }
};
