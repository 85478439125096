import React from "react";

const Divider = ({ height = "1px", background = "transparent" }) => {
    // Define the styles directly; the default height is 1px and the default background is transparent
    const dividerStyle = {
        width: "100%",
        height: height,
        background: background,
    };

    return <div style={dividerStyle} />;
};

export default Divider;
