// NavbarData.js
export const navbarData = {
    logo: {
        image: "/img/logo.svg",
        url: "/"
    },
    menuItems: [
        {
            text: "Solutions",
            url: "/solutions",
            hasDropdown: true,
            dropdownItems: [
                { text: "For Fleets", url: "/fleet" },
                { text: "For Insurers", url: "/insurers" }
            ]
        },
        { text: "Technology", url: "/technology" },
        { text: "Driver's app", url: "/drivers-app" },
        { text: "Industries", url: "/industries" },
        { text: "Support", url: "/support" }
    ],
    cta: {
        text: "Book a demo",
        url: "/contact-us"
    }
};
