import React, { Component } from 'react';
import { Button } from '../components/Button';
import '../styles/App.css';
import '../styles/Home.css';
import { FaCheckCircle } from "react-icons/fa";
import Divider from '../components/Divider';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import LetsTalk from '../components/LetsTalk';
import { withTranslation } from 'react-i18next';

class Home extends Component {
    render() {
        const { i18n } = this.props;

        return (
            <>
                <div className='home-background'>
                    <div className="home-landing-title">
                        <h1 className='h1-semibold'>
                            {i18n.language === 'ar' ? 'تعرف على رؤية' : 'Meet Rooya Vision'}
                        </h1>
                        <p className='home-landing-title-text body-m-regular grays-500'>
                            {i18n.language === 'ar' 
                                ? 'برنامج يعزز سلامة وكفاءة الأسطول في المملكة العربية السعودية باستخدام الذكاء الاصطناعي التليماتيك والكاميرات المزدوجة'
                                : 'Software that boosts fleet safety and efficiency in Saudi Arabia with AI-telematics and dual dash-cams'}
                        </p>
                        <Button color="green" path="/industries">
                            <span className='button-m-semibold'>
                                {i18n.language === 'ar' ? 'اكتشف حلولنا' : 'Discover our solutions'}
                            </span>
                        </Button>
                        <Divider height='80px' />
                    </div>
                    <div style={{ textAlign: "center", padding: "2rem", color: "white" }}>
                        <div className="grid-container">
                            <div className="item item1">
                                <p className='subtitle h8-semibold'>
                                    {i18n.language === 'ar' ? 'تعزيز سلامة الأسطول مع الذكاء الاصطناعي التليماتيك' : 'Enhance fleet safety with AI-telematics'}
                                </p>
                            </div>
                            <div className="item item2">
                                <p className='subtitle h8-semibold'>
                                    {i18n.language === 'ar' ? 'تقنية كاميرات المراقبة المزدوجة المتقدمة' : 'Advanced dual dash-cam technology'}
                                </p>
                            </div>
                            <div className="item item3">
                                <p className='subtitle h8-semibold'>
                                    {i18n.language === 'ar' ? 'تحسين سلوك وكفاءة السائق' : 'Improve driver behaviour and efficiency'}
                                </p>
                            </div>
                            <div className="item item4">
                                <p className='subtitle h8-semibold'>
                                    {i18n.language === 'ar' ? 'تحسين أداء الأسطول' : 'Optimise fleet performance'}
                                </p>
                            </div>
                            <div className="item item5">
                                <p className='subtitle h8-semibold'>
                                    {i18n.language === 'ar' ? 'ثورة في التأمين مع الرؤى المدعومة بالذكاء الاصطناعي' : 'Revolutionize insurance with AI-powered insights'}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='home-landing-title'>
                        <Divider height='150px' />
                        <span className='button-s-semibold rooya-text-gradient'>
                            {i18n.language === 'ar' ? 'حلولنا' : 'Our solutions'}
                        </span>
                        <h1 className='h5-semibold'>
                            {i18n.language === 'ar' ? 'كيف يمكننا مساعدة عملك؟' : 'How can we help your business?'}
                        </h1>
                        <p className='body-m-regular home-landing-title-text'>
                            {i18n.language === 'ar' 
                                ? 'سواء كنت تدير عمليات الأسطول أو تشرف على عمليات التأمين، فإن حلولنا المصممة خصيصًا توفر مراقبة في الوقت الفعلي، وتحليلات تنبؤية، وتكامل سلس لتلبية احتياجاتك المحددة عبر جميع الصناعات.'
                                : 'Whether you manage fleet operations or oversee insurance processes, our tailored solutions offer real-time monitoring, predictive analytics, and seamless integration to meet your specific needs across all industries.'}
                        </p>
                        <Button color="white" path="/contact-us">
                            {i18n.language === 'ar' ? 'احجز عرضًا توضيحيًا معنا' : 'Book a demo with us'}
                        </Button>
                        <Divider height='150px' />
                    </div>
                </div>
                <div className='industries-we-serve'>
                    <div className="industries-container">
                        <div className="industries-column">
                            <span className='button-s-semibold rooya-text-gradient'>
                                {i18n.language === 'ar' ? 'الحلول والخدمات' : 'Solutions and services'}
                            </span>
                            <h1 className='h5-semibold'>
                                {i18n.language === 'ar' ? 'الصناعات التي نخدمها' : 'Industries we serve'}
                            </h1>
                            <p className='body-m-regular'>
                                {i18n.language === 'ar'
                                    ? 'تحسين كفاءة الأسطول وسلامته وأدائه عبر قطاعات اللوجستيات والتوصيل والحكومة والنفط والغذاء والتصنيع والتجزئة والبناء والبنوك والتوصيل المشترك والأساطيل الشركات وقطاعات التأمين'
                                    : 'Optimise fleet efficiency, safety, and performance across logistics, delivery, government, oil, food, manufacturing, retail, construction, banking, ride-sharing, corporate fleets, and insurance sectors'}
                            </p>
                            <Button color="white" path="/industries" style={{ border: "none", boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.2)', marginBottom: "1rem" }}>
                                {i18n.language === 'ar' ? 'معرفة المزيد' : 'Learn more'}
                            </Button>
                            <img src={require('../images/trucks-mobile.png')} alt="Trucks" className='trucks' />
                        </div>
                        <div className="industries-column">
                            <div className='industries-div'>
                                <div>
                                    <img src='/img/laptop-green.svg' style={{marginRight: "16px"}} alt="1" />
                                    <img src='/img/app-green.svg' alt="1" />
                                </div>
                                <h1 className='h5-semibold'>
                                    {i18n.language === 'ar' ? 'لمشغلي الأساطيل' : 'For fleet operators'}
                                </h1>
                                <p className='body-m-regular'>
                                    {i18n.language === 'ar'
                                        ? 'عزز إدارة الأسطول مع التتبع الفوري والتنبيهات الفورية والتقارير الأداء القابلة للتخصيص. اكتشف كيف يمكن لتقنية التليماتيك الخاصة برويا تبسيط عمليات الأسطول الخاصة بك'
                                        : 'Enhance fleet management with real-time tracking, instant alerts, and customisable performance reports. Discover how Rooya AI-telematics can streamline your fleet operations'}
                                </p>
                                <Button color="white" path="/fleet">
                                    {i18n.language === 'ar' ? 'اكتشف حلولنا →' : 'Discover our solutions →'}
                                </Button>
                            </div>
                            <div className='industries-div'>
                                <div>
                                    <img src='/img/laptop-orange.svg' style={{marginRight: "16px"}} alt="1" />
                                    <img src='/img/app-orange.svg' alt="1" />
                                </div>
                                <h1 className='h5-semibold'>
                                    {i18n.language === 'ar' ? 'لشركات التأمين' : 'For insurers'}
                                </h1>
                                <p className='body-m-regular'>
                                    {i18n.language === 'ar'
                                        ? 'تحسين تقييم المخاطر وتقليل المطالبات باستخدام التليماتيك المتقدم وبيانات الأداء التفصيلية. اكتشف كيف تدعم تقنية التليماتيك الخاصة برويا اتخاذ قرارات مستنيرة ودقيقة في الاكتتاب'
                                        : 'Optimise risk assessment and reduce claims with advanced telematics and detailed performance data. Explore how Rooya AI-telematics supports accurate underwriting and informed decisions'}
                                </p>
                                <Button color="white" path="/insurers">
                                    {i18n.language === 'ar' ? 'اكتشف حلولنا →' : 'Discover our solutions →'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sowhyrooya-container'>
                    <div className='sowhyrooya-title'>
                        <span className='button-s-semibold rooya-text-gradient'>
                            {i18n.language === 'ar' ? 'أبرز الميزات والفوائد' : 'Featured highlights and benefits'}
                        </span>
                        <h1 className='h5-semibold'>
                            {i18n.language === 'ar' ? 'لماذا يختار العملاء رويا؟' : 'So why clients choose Rooya?'}
                        </h1>
                        <p className='body-m-regular'>
                            {i18n.language === 'ar'
                                ? 'نحن نحدث ثورة في إدارة الأسطول والتأمين بتقنيتنا المتقدمة للتليماتيك المعزز برؤية الذكاء الاصطناعي لاتخاذ قرارات محسنة'
                                : 'We\'re revolutionising fleet management and insurance with our cutting-edge AI-vision telematics advanced technology for optimised decision making'}
                        </p>
                    </div>
                    <div className="sowhyrooya">
                        <div className="sowhyrooya-div">
                            <img src={require('../images/1 - Icon.png')} alt="1"
                                style={{
                                    maxWidth: "100px",
                                    display: "block",
                                    margin: "0 auto"
                                }}
                            />
                            <h1 className='h6-semibold'>
                                {i18n.language === 'ar' ? 'تليماتيك الرؤية المتقدمة بالذكاء الاصطناعي:' : 'Advanced AI-vision telematics:'}
                            </h1>
                            <p className='sowhyrooya-topic'>
                                <div className='sowhyrooya-p'>
                                    <FaCheckCircle style={{ color: '#479f6d', fontSize: '30px',marginRight:'10px', marginLeft:'10px'}} />
                                    {i18n.language === 'ar' ? 'كاميرات مزدوجة بتسجيل فيديو عالي الدقة ونظام تحديد المواقع المتكامل' : 'Dual dash-cams with HD video recording and Integrated GPS'}
                                </div>
                            </p>
                            <p className='sowhyrooya-topic'>
                                <div className='sowhyrooya-p'>
                                    <FaCheckCircle style={{ color: '#479f6d', fontSize: '30px', marginRight:'10px', marginLeft:'10px' }} />
                                    {i18n.language === 'ar' ? 'نظام مراقبة السائق (DMS) مع تنبيهات صوتية فورية لزيادة السلامة' : 'Driver Monitoring System (DMS) with real-time audio alerts for enhanced safety'}
                                </div>
                            </p>
                            <p className='sowhyrooya-topic'>
                                <div className='sowhyrooya-p'>
                                    <FaCheckCircle style={{ color: '#479f6d', fontSize: '30px', marginRight:'10px', marginLeft:'10px'}} />
                                    {i18n.language === 'ar' ? 'تحليل الفيديو في الوقت الحقيقي المدعوم بالذكاء الاصطناعي لاكتشاف وتصحيح سلوك السائق' : 'AI-driven real-time video analysis for detecting and correcting driver behaviour'}
                                </div>
                            </p>
                        </div>
                        <div className="sowhyrooya-div sowhyrooya-swiper-desktop">
                            <Swiper
                                modules={[Navigation, Pagination, A11y]}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                                style={{ maxWidth: "380px", marginBottom: "50px", padding: "30px 0" }}
                            >
                                <SwiperSlide>
                                    <img
                                        src={require("../images/Homepage - SoWhyRooya1.png")}
                                        alt={i18n.language === 'ar' ? 'هاتف محمول 1' : 'Cell phone 1'}
                                        style={{ maxWidth: "350px" }}
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src={require("../images/Homepage - SoWhyRooya2.png")}
                                        alt={i18n.language === 'ar' ? 'هاتف محمول 2' : 'Cell phone 2'}
                                        style={{ maxWidth: "350px" }}
                                    />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                        <div className="sowhyrooya-div">
                            <img src={require('../images/2 - Icon.png')} alt="2"
                                style={{
                                    maxWidth: "100px",
                                    display: "block",
                                    margin: "0 auto"
                                }}
                            />
                            <h1 className='h6-semibold'>
                                {i18n.language === 'ar' ? 'تحليل البيانات الشامل:' : 'Comprehensive data analytics:'}
                            </h1>
                            <p className='sowhyrooya-topic'>
                                <div className='sowhyrooya-p'>
                                    <FaCheckCircle style={{ color: '#479f6d', fontSize: '30px', marginRight: '10px' }} />
                                    {i18n.language === 'ar' ? 'تحليل المخاطر التفصيلي وتقارير الأداء للتحسين المستمر' : 'Detailed risk analysis and performance reports for continuous improvement'}
                                </div>
                            </p>
                            <p className='sowhyrooya-topic'>
                                <div className='sowhyrooya-p'>
                                    <FaCheckCircle style={{ color: '#479f6d', fontSize: '30px', marginRight: '10px' }} />
                                    {i18n.language === 'ar' ? 'جمع وتحليل البيانات في الوقت الفعلي للحصول على تعليقات فورية' : 'Real-time data collection and analysis for immediate feedback'}
                                </div>
                            </p>
                            <p className='sowhyrooya-topic'>
                                <div className='sowhyrooya-p'>
                                    <FaCheckCircle style={{ color: '#479f6d', fontSize: '30px', marginRight: '10px' }} />
                                    {i18n.language === 'ar' ? 'تخزين البيانات الآمن في السحابة مع وصول سهل لمديري الأساطيل' : 'Secure cloud-based data storage with easy access for fleet managers'}
                                </div>
                            </p>
                        </div>
                        <div className="sowhyrooya-div sowhyrooya-swiper-mobile">
                            <Swiper
                                modules={[Navigation, Pagination, A11y]}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                                style={{ maxWidth: "380px", marginBottom: "50px", padding: "30px 0" }}
                            >
                                <SwiperSlide>
                                    <img
                                        src={require("../images/Homepage - SoWhyRooya1.png")}
                                        alt={i18n.language === 'ar' ? 'هاتف محمول 1' : 'Cell phone 1'}
                                        style={{ maxWidth: "350px" }}
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <img
                                        src={require("../images/Homepage - SoWhyRooya2.png")}
                                        alt={i18n.language === 'ar' ? 'هاتف محمول 2' : 'Cell phone 2'}
                                        style={{ maxWidth: "350px" }}
                                    />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
                <div className='enhanced-div'>
                    <div className='sowhyrooya-swiper-desktop'>
                        <img src={require('../images/Homepage - Enhanced.png')} alt={i18n.language === 'ar' ? 'هاتف محمول' : 'Cellphone'} />
                    </div>
                    <div className='enhanced-column'>
                        <img src={require('../images/3 - Icon.png')} alt="3" style={{ maxWidth: "70px" }} />
                        <h1 className='h6-semibold' style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                            {i18n.language === 'ar' ? 'تحسين السلامة والكفاءة:' : 'Enhanced safety and efficiency:'}
                        </h1>
                        <p style={{ padding: '10px' }}>
                            <div className='sowhyrooya-p'>
                                <FaCheckCircle style={{ color: 'lightgreen', fontSize: '30px', marginRight: '10px', marginLeft: '10px' }}  />
                                {i18n.language === 'ar' ? 'تحليل المخاطر التفصيلي وتقارير الأداء للتحسين المستمر' : 'Detailed risk analysis and performance reports for continuous improvement'}
                            </div>
                        </p>
                        <p style={{ padding: '10px' }}>
                            <div className='sowhyrooya-p'>
                                <FaCheckCircle style={{ color: 'lightgreen', fontSize: '30px', marginRight: '10px', marginLeft: '10px' }} />
                                {i18n.language === 'ar' ? 'جمع وتحليل البيانات في الوقت الفعلي للحصول على تعليقات فورية' : 'Real-time data collection and analysis for immediate feedback'}
                            </div>
                        </p>
                        <p style={{ padding: '10px' }}>
                            <div className='sowhyrooya-p'>
                                <FaCheckCircle style={{ color: 'lightgreen', fontSize: '30px', marginRight: '10px', marginLeft: '10px' }} />
                                {i18n.language === 'ar' ? 'تخزين البيانات الآمن في السحابة مع وصول سهل لمديري الأساطيل' : 'Secure cloud-based data storage with easy access for fleet managers'}
                            </div>
                        </p>
                        <Button color="white" path="/technology" style={{ marginBottom: '50px', marginTop: '20px' }}>
                            {i18n.language === 'ar' ? 'معرفة المزيد على صفحة التقنية' : 'Learn more on tech-page'}
                        </Button>
                    </div>
                    <div className='sowhyrooya-swiper-mobile'>
                        <img src={require('../images/Homepage - Enhanced.png')} alt={i18n.language === 'ar' ? 'هاتف محمول' : 'Cellphone'} />
                    </div>
                </div>
                {/* Let's Talk start*/}
                <LetsTalk />
                {/* Let's Talk end*/}
            </>
        );
    }
}

export default withTranslation()(Home);
