export const supportData = {
  banner: {
      header: "خدمة العملاء",
      title: "تحدث معنا",
      description: "سواء كنت بحاجة إلى مساعدة بشأن منتجاتنا، أو استكشاف الأخطاء وإصلاحها، أو لديك استفسارات عامة، فإن فريق الدعم لدينا موجود لمساعدتك",
      contact: [
          {
              title: "رقم الهاتف",
              description: "+966 11 425 5665",
              icon: "/img/map-pin.svg",
              colour: "#0DAB724D"
          },
          {
              title: "البريد الالكتروني",
              description: "support@rooya.ai",
              icon: "/img/e-mail.svg",
              colour: "#FDB12D33"
          },
          {
              title: "ساعات الدعم",
              description: "من الأحد إلى الخميس، من الساعة 9 صباحا حتى 6 مساءً",
              icon: "/img/clock.svg",
              colour: "#F1585333"
          }
      ]
  },

  categories: [
      {
          header: "الأسئلة و الاستفسارات",
          name: "أسئلة عامة",
          questions: [
              {
                  question: "ما هي شركة رؤية التيليماتية المدعومة بالذكاء الاصطناعي؟",
                  answer: "توفر رؤية التيليماتية المدعومة بالذكاء الاصطناعي حلولاً متقدمة لقنية التيليماتية باستخدام الذكاء الاصطناعي والأنظمة القائمة على الرؤية لتعزيز إدارة الأسطول و السلامة على الطرق."
              },
              {
                  question: "كيف يمكنني التواصل مع شركة رؤية التيليماتية المدعومة بالذكاء الاصطناعي؟",
                  answer: "يمكن التواصل معنا عن طريق الهاتف: +966 11 425 5665 أو البريد الالكتروني support@rooya.ai"
              },
              {
                  question: "أين تقع شركة رؤية؟",
                  answer: "المقر الأساسي يوجد في شارع التحلية، مجمع الدغيثر، العليا، الرياض، المملكة العربية السعودية."
              }
          ]
      },
      {
          header: "الأسئلة و الاستفسارات",
          name: "المنتجات والخدمات",
          questions: [
              {
                  question: "ما هي الحلول التي تقدمها شركة رؤية؟",
                  answer: "نحن نقدم إدارة الأسطول، وإدارة السلامة والمخاطر، وحلول التأمين، وحلول الأسطول القابلة للتخصيص."
              },
              {
                  question: "كيف تعمل تقنية التيليماتية المدعومة بالذكاء الاصطناعي لديكم؟",
                  answer: "يستخدم نظامنا خوارزميات الذكاء الاصطناعي المتقدمة لتحليل سلوك السائق وأداء السيارة وظروف الطريق لتوفير رؤى وتنبيهات في الوقت الفعلي."
              },
              {
                  question: "ما هي الفوائد الرئيسية لتقنية رؤية التيليماتية المدعومة بالذكاء الاصطناعي؟",
                  answer: "تساعد حلولنا على تحسين سلامة السائق، وتقليل استهلاك الوقود، وتحسين تخطيط المسار، وتحسين الأداء العام للأسطول."
              }
          ]
      },
      {
          header: "الأسئلة و الاستفسارات",
          name: "التثبيت والإعداد",
          questions: [
              {
                  question: "كيف أثبت أجهزة تقنية رؤية التيليماتية المدعومة بالذكاء الاصطناعي؟",
                  answer: "التثبيت واضح ومباشر. يتم توفير تعليمات مفصلة مع كل جهاز، وفريق الدعم لدينا متاح لمساعدتك في الإجابة عن أي سؤال."
              },
              {
                  question: "هل أحتاج إلى شخص مختص لتركيب الأجهزة؟",
                  answer: "يوصى بتركيب الأجهزة من قِبل شخص مختص لضمان الأداء الأمثل والدقة."
              },
              {
                  question: "هل يمكنني ربط تقنية رؤية التيليماتية المدعومة بالذكاء الاصطناعي مع الأنظمة الإدارية لدي؟",
                  answer: "نعم، لقد تم تصميم حلولنا لتتكامل بسلاسة مع أنظمة إدارة الأسطول الحالية."
              }
          ]
      },
      {
          header: "الأسئلة و الاستفسارات",
          name: "الدعم الفني",
          questions: [
              {
                  question: "ماذا علي أن أفعل إذا كان جهازي لا يعمل بشكل صحيح؟",
                  answer: "أولاً، تحقق من دليل الجهاز للتعرف على خطوات استكشاف الأخطاء وإصلاحها. إذا استمرت المشكلة، فاتصل بفريق الدعم لدينا للحصول على المساعدة."
              },
              {
                  question: "كم مرة أحتاج إلى تحديث البرنامج؟",
                  answer: "نوصي بتحديث البرنامج بمجرد توفر إصدار جديد لضمان الأداء الأمثل."
              },
              {
                  question: "ما نوع البيانات التي يتم جمعها بواسطة نظام رؤية التيليماتية؟",
                  answer: "يقوم رؤية التيليماتية بجمع البيانات مثل موقع السيارة والسرعة والتسارع والتوقف المفاجئ ومقاييس سلوك القيادة الأخرى."
              }
          ]
      },
      {
          header: "الأسئلة و الاستفسارات",
          name: "خصوصية البيانات والأمن",
          questions: [
              {
                  question: "كيف تتم حماية بياناتي؟",
                  answer: "نحن نستخدم خوادم سحابية آمنة مقرها في المملكة العربية السعودية، معززة ببروتوكولات التشفير والأمان المتقدمة لحماية بياناتك."
              },
              {
                  question: "من لديه حق الوصول إلى بياناتي؟",
                  answer: "يمكن للموظفين المصرح لهم فقط الوصول إلى بياناتك، ونحن نلتزم بلوائح حماية البيانات الصارمة لضمان خصوصيتك."
              },
              {
                  question: "هل يمكنني طلب حذف البيانات؟",
                  answer: "نعم، يمكنك طلب حذف البيانات في أي وقت. يرجى الاتصال بفريق الدعم لدينا للحصول على المساعدة."
              }
          ]
      }
  ],

  talk: {
      info: {
          title: "تحدث معنا",
          description: "نحن هنا لمساعدتك على تحسين عمليات الأسطول الخاص بك وتعزيز السلامة",
          listDescription: "تواصل معنا لمزيد من المعلومات أو:",
          listItems: [
              "اطلب عرض توضيحي",
              "تواصل مع فريق الدعم"
          ],
          listImg: "/img/white-tick.svg",
          contactDetails: [
              {
                  label: "البريد الالكتروني",
                  value: "contact@rooya.ai"
              },
              {
                  label: "رقم الهاتف",
                  value: "+966 11 425 5665"
              },
              {
                  label: "العنوان",
                  value: "شارع التحلية، مجمع الدغيثر، العليا، الرياض، المملكة العربية السعودية"
              }
          ]
      },
      form: {
          name: {
              label: "الاسم*",
              placeholder: "ما هو اسمك؟",
              type: "input"
          },
          email: {
              label: "البريد الالكتروني*",
              placeholder: "ما هو الايميل الذي تود أن نتواصل معك عن طريقه؟",
              type: "input"
          },
          message: {
              label: "اكتب رسالتك*",
              placeholder: "كيف يمكننا خدمتك؟",
              type: "textarea"
          },
          checkbox: {
              label: "نعم, أريد تلقي اخر التحديثات عن طريق البريد الالكتروني",
              type: "checkbox"
          },
          submitButton: {
              text: "إرسال",
              type: "button"
          },
          tandc: {
              text: `بالضغط على "إرسال" فإنك تقر بالشروط و الأحكام`,
              tc: "Terms and Conditions"
          }
      }
  }
};
