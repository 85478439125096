export const footerData = {
    newsletter: {
        title: "Stay Informed with Weekly Updates",
        description: "We'll send you a carefully curated newsletter every week. No spam, just valuable insights.",
        buttonText: "Get started",
        termsText: "By clicking 'Get started' you agree to the",
        termsTextUrl: "Terms and conditions",
        termsUrl: "/terms-conditions"
    },
    columns: [
        {
            title: "Solutions",
            links: ["Fleet", "Insurers", "Industry", "Drivers' App"],
            paths: ["/fleet", "/insurers", "/industries", "/drivers-app"]
        },
        {
            title: "Company",
            links: ["CSR", "Contact us", "Support"],
            paths: ["/", "/contact-us", "/support"]
        },
        {
            title: "Client portal",
            links: ["Privacy policy", "Terms and conditions"],
            paths: ["/privacy-policy", "/terms-conditions"]
        }
    ],
    logo: {
        url: "/",
        image: "/img/logo-small.svg"
    },
    copyright: "© 2024 Rooya. All rights reserved",
    socialLinks: [
        
        {
            url: "https://x.com/rooya_ai",
            icon: "/img/twitter.svg"
        },
        {
            url: "https://www.instagram.com/rooya_ai_telematics",
            icon: "/img/instagram.svg"
        },
        {
            url: "https://www.linkedin.com/company/rooya",
            icon: "/img/linkedin.svg"
        }
    ]
};
