// supportData.js

import { type } from "@testing-library/user-event/dist/type";

export const supportData = {
  banner: {
    header: "Customer Support",
    title: "Let's talk",
    description:
      "Whether you need assistance with our products, troubleshooting, or have general inquiries, our support team is here to help",
    contact: [
      {
        title: "Phone",
        description: "+966 11 425 5665",
        icon: "/img/map-pin.svg",
        colour: "#0DAB724D"
      },
      {
        title: "Email",
        description: "support@rooya.ai",
        icon: "/img/e-mail.svg",
        colour: "#FDB12D33"
      },
      {
        title: "Support hours",
        description: "Sunday to Thursday, 9AM to 6PM (GMT+3)",
        icon: "/img/clock.svg",
        colour: "#F1585333"
      }
    ]
  },


  categories: [
    {
      header: "FAQ",
      name: "General Questions",
      questions: [
        {
          question: "What is Rooya AI Telematics?",
          answer: "Rooya AI-telematics provides advanced telematics solutions using AI and vision-based systems to enhance fleet management and road safety."
        },
        {
          question: "How can I contact Rooya AI-telematics?",
          answer: "You can contact us via phone at +966 11 425 5665 or email at support@rooya-ai"
        },
        {
          question: "Where is Rooya AI-telematics located?",
          answer: "Our headquarters is located in Riyadh-al-Olaya-Tahlia  Street-Duaithar Compound, Kingdom of Saudi Arabia."
        }
      ]
    },
    {
      header: "FAQ",
      name: "Product & Services",
      questions: [
        {
          question: "What solutions does Rooya AI-telematics offer?",
          answer: "We offer fleet management, safety and risk management, insurance solutions, and customisable fleet solutions."
        },
        {
          question: "How does your AI-driven telematics system work?",
          answer: "Our system uses advanced AI algorithms to analyse driver behaviour, vehicle performance, and road conditions to provide real-time insights and alerts."
        },
        {
          question: "What are the key benefits of using Rooya AI-telematics?",
          answer: "Our solutions help improve driver safety, reduce fuel consumption, optimise route planning, and enhance overall fleet performance."
        }
      ]
    },
    {
      header: "FAQ",
      name: "Installation & Setup",
      questions: [
        {
          question: "How do I install the Rooya AI-telematics devices?",
          answer: "Installation is straightforward. Detailed instructions are provided with each device, and our support team is available to assist you with any questions."
        },
        {
          question: "Do I need professional installation for the devices?",
          answer: "Professional installation is recommended to ensure optimal performance and accuracy."
        },
        {
          question: "Can I integrate Rooya AI-telematics with my existing systems?",
          answer: "Yes, our solutions are designed to integrate seamlessly with existing fleet management systems."
        }
      ]
    },
    {
      header: "FAQ",
      name: "Technical Support",
      questions: [
        {
          question: "What should I do if my device is not working properly?",
          answer: "First, check the device manual for troubleshooting steps. If the issue persists, contact our support team for assistance."
        },
        {
          question: "How often do I need to update the software?",
          answer: "We recommend updating the software as soon as a new version is available to ensure optimal performance."
        },
        {
          question: "What kind of data is collected by the telematics system?",
          answer: "The telematics system collects data such as vehicle location, speed, acceleration, braking, and other driving behaviour metrics"
        }
      ]
    },
    {
      header: "FAQ",
      name: "Data Privacy and Security",
      questions: [
        {
          question: "How is my data protected?",
          answer: "We use secure cloud servers domiciled in Saudi Arabia, enhanced with advanced encryption and security protocols to protect your data."
        },
        {
          question: "Who has access to my data?",
          answer: "Only authorised personnel have access to your data, and we adhere to strict data protection regulations to ensure your privacy."
        },
        {
          question: "Can I request data deletion?",
          answer: "Yes, you can request data deletion at any time. Please contact our support team for assistance."
        }
      ]
    },
  ],


  talk: {
    info: {
      title: "Let's talk",
      description: "We are here to help you optimise your fleet operations and enhance safety!",
      listDescription: "Get in touch with us for more information or:",
      listItems: [
        "Request a demo",
        "Speak to our support team"
      ],
      listImg: "/img/white-tick.svg",
      contactDetails: [
        {
          label: "Email",
          value: "contact@rooya.ai"
        },
        {
          label: "Phone number",
          value: "+966 11 425 5665"
        },
        {
          label: "Address",
          value: "Riyadh-al-Olaya-Tahlia Street-Duaithar Compound, Kingdom of Saudi Arabia"
        }
      ]
    },
    form: {
      fields: [
        {
          label: "Your name*",
          placeholder: "What is your name?",
          type: "input"
        },
        {
          label: "Email address*",
          placeholder: "What is your contact email?",
          type: "input"
        },
        {
          label: "Write your message*",
          placeholder: "How can we assist you?",
          type: "textarea"
        }
      ],
      checkbox: {
        label: "Yes, I want to receive latest updates via the email",
        type: "checkbox"
      },
      submitButton: {
        text: "Send message",
        type: "button"
      },
      tandc: {
        text: "By clicking Send message you agree to the ",
        tc: "Terms and Conditions"
      }
    }
  }
};
