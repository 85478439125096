export const letsTalkData = {
    talk: {
        info: {
            title: "تحدث معنا",
            description: "نحن هنا لمساعدتك على تحسين عمليات الأسطول الخاص بك وتعزيز السلامة",
            listDescription: "تواصل معنا لمزيد من المعلومات أو:",
            listItems: [
                "اطلب عرض توضيحي",
                "تواصل مع فريق الدعم"
            ],
            listImg: "/img/white-tick.svg",
            contactDetails: [
                {
                    label: "البريد الالكتروني",
                    value: "contact@rooya.ai"
                },
                {
                    label: "رقم الهاتف",
                    value: "+966 11 425 5665"
                },
                {
                    label: "العنوان",
                    value: "شارع التحلية، مجمع الدغيثر، العليا، الرياض، المملكة العربية السعودية"
                }
            ]
        },
        form: {
            name: {
                label: "الاسم*",
                placeholder: "ما هو اسمك؟",
                type: "input"
            },
            email: {
                label: "البريد الالكتروني*",
                placeholder: "ما هو الايميل الذي تود أن نتواصل معك عن طريقه؟",
                type: "input"
            },
            message: {
                label: "اكتب رسالتك*",
                placeholder: "كيف يمكننا خدمتك؟",
                type: "textarea"
            },
            checkbox: {
                label: "نعم, أريد تلقي اخر التحديثات عن طريق البريد الالكتروني",
                type: "checkbox"
            },
            submitButton: {
                text: "إرسال",
                type: "button"
            },
            tandc: {
                text: `بالضغط على "إرسال" فإنك تقر بالشروط و الأحكام`,
                tc: "Terms and Conditions"
            }
        }
    }
};
