import React, { useState } from "react";
import { useTranslation } from 'react-i18next'; 
import "./../styles/Support.css";
import "./../styles/ContactUs.css";
import "./../styles/Typography.css";
import Divider from "../components/Divider";
import { FaTheRedYeti } from "react-icons/fa";

const ContactUs = () => {
    const { t } = useTranslation(); 

    const banner = t('contactUsData.banner', { returnObjects: true });
    const talk = t('contactUsData.talk', { returnObjects: true });

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        agreeToTerms: false,
    });


    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });

        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = t("contactUsData.form.errors.name", "Name is required");
        }
        if (!formData.email.trim()) {
            newErrors.email = t("contactUsData.form.errors.email", "Email is required");
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = t("contactUsData.form.errors.invalidEmail", "Email is invalid");
        }
        if (!formData.message.trim()) {
            newErrors.message = t("contactUsData.form.errors.message", "Message is required");
        }

        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setIsSubmitting(true);

        try {
            const response = await fetch('https://fleet-dev.polysurance.com:6021/polyDrive/v1/contact-us', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setSuccessMessage(t("contactUsData.form.success", "Message sent successfully!"));
                setFormData({
                    name: '',
                    email: '',
                    message: '',
                    agreeToTerms: false,
                });
                setErrors({});
            } else {
                console.error(t("contactUsData.form.error", "Failed to send message"));
                setSuccessMessage("");
            }
        } catch (error) {
            console.error(t("contactUsData.form.error", "Error sending message:"), error);
            setSuccessMessage("");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <div className='contact-banner-container'>
                <div className="contact-banner-left">
                    <div className='button-m-semibold rooya-text-gradient'>{banner.header}</div>
                    <h1 className='contact-banner-title h5-semibold grays-600'>{banner.title}</h1>
                    <p className='contact-banner-description body-m-regular grays-400'>{banner.description}</p>
                </div>
                <div className="contact-banner-right">
                    {banner.contact.map((feature, index) => (
                        <div key={index} className='contact-banner-item'>
                            <div className="contact-banner-icon-background" style={{ '--dynamic-bg-color': feature.colour }}>
                                <img src={feature.icon} alt={feature.title} className='contact-banner-icon' />
                            </div>
                            <div className="contact-item-details">
                                <h2 className='body-s-regular grays-400'>{feature.title}</h2>
                                <p className='body-m-semibold grays-500'>{feature.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className='contact-body-container'>
                <div className="contact-body-info">
                    <h1 className='h4-semibold'>{talk.info.title}</h1>
                    <p className='body-s-regular'>{talk.info.description}</p>
                    <p className='body-s-regular'>{talk.info.listDescription}</p>
                    <ul>
                        {talk.info.listItems.map((item, index) => (
                            <li key={index} className='contact-list-item'>
                                <img src={talk.info.listImg} alt="" />
                                <p className='body-s-regular'>{item}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="contact-inputs">
                        <div className={`contact-form-field ${errors.name ? 'error' : ''}`}>
                            <label className='body-s-medium grays-200'>{talk.form.name.label}</label>
                            <input
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                className="rooya-input body-s-regular"
                                placeholder={talk.form.name.placeholder}
                            />
                            {errors.name && <p className="error-message">{errors.name}</p>}
                        </div>
                        <div className={`contact-form-field ${errors.email ? 'error' : ''}`}>
                            <label className='body-s-medium grays-200'>{talk.form.email.label}</label>
                            <input
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="rooya-input body-s-regular"
                                placeholder={talk.form.email.placeholder}
                            />
                            {errors.email && <p className="error-message">{errors.email}</p>}
                        </div>
                    </div>
                    <div className={`contact-form-field rooya-textarea-parent ${errors.message ? 'error' : ''}`}>
                        <label className='body-s-medium grays-200'>{talk.form.message.label}</label>
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            className="rooya-textarea body-s-regular"
                            placeholder={talk.form.message.placeholder}
                        />
                        {errors.message && <p className="error-message">{errors.message}</p>}
                    </div>
                    <div className="rooya-checkbox-container">
                        <input
                            name="agreeToTerms"
                            type="checkbox"
                            checked={formData.agreeToTerms}
                            onChange={handleChange}
                            className="rooya-checkbox"
                        />
                        <span className="grays-200 body-s-regular">{talk.form.checkbox.label}</span>
                    </div>
                    <button type="submit" className="rooya-form-submit button-m-semibold" disabled={isSubmitting}>
                        {isSubmitting ? t("contactUsData.form.submitting", "Submitting...") : successMessage || talk.form.submitButton.text}
                    </button>
                </form>
            </div>
        </>
    );
}

export default ContactUs;
