import React, { useEffect } from "react";
import ScrollToTopButton from "./components/ScrollToTopButton";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Technology from "./pages/Technology";
import ContactUs from "./pages/ContactUs";
import DriversApp from "./pages/DriversApp";
import Support from "./pages/Support";
import Fleet from "./pages/Fleet";
import Insurers from "./pages/Insurers";
import Industries from "./pages/Industries";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import { fleetData } from "./lib/pagesData/locales/en/fleetData";
import ReactGA from "react-ga4";
import i18n from './i18n'; // Import for i18next

// Initialize Google Analytics with your measurement ID
ReactGA.initialize("G-RV6F0SM2PJ"); 

// Component to track page views
const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const page_path = location.pathname + location.search;
    ReactGA.send({ hitType: "pageview", page: page_path });
  }, [location]);

  return null; 
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null; 
};

function App() {
    useEffect(() => {
        // Set the document direction based on the language
        document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
    }, [i18n.language]);

    return (
        <Router>
            <Navbar />
            <ScrollToTop /> 
            <PageTracker />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/technology' element={<Technology />} />
                <Route path='/contact-us' element={<ContactUs />} />
                <Route path='/drivers-app' element={<DriversApp />} />
                <Route path='/fleet' element={<Fleet data={fleetData} />} />
                <Route path='/insurers' element={<Insurers />} />
                <Route path='/support' element={<Support />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/terms-conditions' element={<TermsConditions />} />
                <Route path='/industries' element={<Industries/>} />
            </Routes>
            <Footer />
            <ScrollToTopButton />
        </Router>
    );
}

export default App;