import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import English and Arabic data from the same file
import { fleetData as fleetDataEN, insurersData as insurersDataEN } from './lib/pagesData/locales/en/fleetData';
import { fleetData as fleetDataAR, insurersData as insurersDataAR } from './lib/pagesData/locales/ar/fleetData';

// Other translation imports (e.g., contactUsData, navbarData, etc.)
import { contactUsData as contactUsDataEN } from './lib/pagesData/locales/en/contactUsData';
import { DriversAppData as DriversAppDataEN } from './lib/pagesData/locales/en/DriversAppData';
import { IndustriesData as IndustriesDataEN } from './lib/pagesData/locales/en/IndustriesData';
import { TechnologyData as TechnologyDataEN } from './lib/pagesData/locales/en/TechnologyData';
import { footerData as footerDataEN } from './lib/pagesData/locales/en/footerData';
import { letsTalkData as letsTalkDataEN } from './lib/pagesData/locales/en/letsTalkData';
import { navbarData as navbarDataEN } from './lib/pagesData/locales/en/navbarData';
import { supportData as supportDataEN } from './lib/pagesData/locales/en/supportData';

import { contactUsData as contactUsDataAR } from './lib/pagesData/locales/ar/contactUsData';
import { DriversAppData as DriversAppDataAR } from './lib/pagesData/locales/ar/DriversAppData';
import { IndustriesData as IndustriesDataAR } from './lib/pagesData/locales/ar/IndustriesData';
import { TechnologyData as TechnologyDataAR } from './lib/pagesData/locales/ar/TechnologyData';
import { footerData as footerDataAR } from './lib/pagesData/locales/ar/footerData';
import { letsTalkData as letsTalkDataAR } from './lib/pagesData/locales/ar/letsTalkData';
import { navbarData as navbarDataAR } from './lib/pagesData/locales/ar/navbarData';
import { supportData as supportDataAR } from './lib/pagesData/locales/ar/supportData';

// Combine your translation files into the resources object
const resources = {
  en: {
    translation: {
      contactUsData: contactUsDataEN,
      DriversAppData: DriversAppDataEN,
      fleetData: fleetDataEN, // Correctly referenced
      IndustriesData: IndustriesDataEN,
      TechnologyData: TechnologyDataEN,
      footerData: footerDataEN,
      letsTalkData: letsTalkDataEN,
      navbarData: navbarDataEN,
      insurersData: insurersDataEN, // Correctly referenced
      supportData: supportDataEN,
    },
  },
  ar: {
    translation: {
      contactUsData: contactUsDataAR,
      DriversAppData: DriversAppDataAR,
      fleetData: fleetDataAR, // Correctly referenced
      IndustriesData: IndustriesDataAR,
      TechnologyData: TechnologyDataAR,
      footerData: footerDataAR,
      letsTalkData: letsTalkDataAR,
      navbarData: navbarDataAR,
      insurersData: insurersDataAR, // Correctly referenced
      supportData: supportDataAR,
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en', // Fallback language is English
    debug: true,
    interpolation: {
      escapeValue: false, // React already does XSS protection
    },
    returnObjects: true, // Ensure objects are returned for nested translations
  });

export default i18n;
