import React from "react";
import "./../styles/Typography.css";
import "./../styles/PrivacyPolicy.css";
import Divider from "../components/Divider";

const PrivacyPolicy = () => {
    return (
        <>
            <div className='fleet-hero' style={{ textAlign: "center", padding: "5rem", backgroundColor: "#292828", color: "white" }}>
                <p className='body-m-regular' style={{ padding: "1rem", color: "#726d6d" }}>Current as of 20 Jan 2024</p>
                <h1 className='h5-semibold'>Privacy Policy</h1>
            </div>
            <Divider height="50px" />
            <div className="div-privacy-policy">
                <h3 className="h3-privacy-policy">HOW WE USE YOUR DATA</h3>
                <p className="p-privacy-policy">We are committed to protecting your privacy. It is important that you understand how we look after your personal data and how we make sure that we meet our legal obligations to you under the UK data protection rules (including associated guidance) (the "Data Protection Laws"). This privacy policy outlines how Polysurance Limited will use, store and share your personal data, and supplements any other fair processing or privacy notices provided to you.</p>
                <p className="p-privacy-policy">If you have any questions in relation to this policy or generally how your personal data is processed by us please contact our Data Protection Officer by letter addressed to: Polysurance, 124 Goswell Road, London, UK, EC1V 7DP; or by email at data@polysurance.com.</p>
                <p className="p-privacy-policy">This policy applies to any personal data that we collect about you when you:</p>
                <p className="p-privacy-policy list">•  visit our websites www.polysurance.com and www.polycam.app (our "website");</p>
                <p className="p-privacy-policy list">•  use our smartphone application PolyCam (our “app”)</p>
            </div>
            <div className="div-privacy-policy">
                <h3 className="h3-privacy-policy">HOW ARE WE</h3>
                <p className="p-privacy-policy">In this privacy policy, the terms "we", "our", and "us" are used to refer to Polysurance Limited, registered in England and Wales with company number 12456074 and registered address 7 Teesdale Gardens, Grange Hill, London, United Kingdom, SE25 6SU.</p>
                <p className="p-privacy-policy">We are a controller of your information which means that we are responsible for looking after it. We will use your personal data fairly, lawfully and in a transparent manner, and in accordance with the Data Protection Laws.</p>
            </div>
            <div className="div-privacy-policy">
                <h3 className="h3-privacy-policy">WHAT INFORMATION DO WE COLLECT ABOUT YOU?</h3>
                <p className="p-privacy-policy">We collect the following information about you:</p>
                <p className="p-privacy-policy list">•  name;</p>
                <p className="p-privacy-policy list">•  email address;</p>
                <p className="p-privacy-policy list">•  age;</p>
                <p className="p-privacy-policy list">•  location;</p>
                <p className="p-privacy-policy list">•  video and audio recordings.</p>
                <p className="p-privacy-policy">Each time you visit our website or app we may also automatically collect information and personal data about your computer for system administration including, where available, your IP address, operating system, browser type and specific actions taken by you. We do this to help us analyse how users use the websites and app (including behaviour patterns and the tracking of visits across multiple devices), to establish more about our website and app users and to assist us in managing your account and improving your experience. Please see our cookies policy for further information about what information may be automatically collected when you visit our website here.</p>
                <p className="p-privacy-policy">Please note that we may combine personal data we receive from other sources with personal data you give to us and personal data we collect about you.</p>
            </div>
            <div className="div-privacy-policy">
                <h3 className="h3-privacy-policy">WHERE DO WE GET THIS INFORMATION FROM?</h3>
                <p className="p-privacy-policy">We collect some of your information directly from you, either through information that you give to us or information that we collect during your visits to our website, usage of our app or through your communications with us.</p>
                <h4 className="h4-privacy-policy">Information that you give us</h4>
                <p className="p-privacy-policy">You may share personal data about yourself and your circumstances by:</p>
                <p className="p-privacy-policy list">•  filling in forms on our app, registering to use our app, and continuing to use our app;</p>
                <p className="p-privacy-policy list">•  recording videos using our app;</p>
                <p className="p-privacy-policy list">•  filling in forms on our website, registering to use our website, and continuing to use our website;</p>
                <p className="p-privacy-policy list">•  giving us information about yourself in any communications with us either by telephone, e-mail, post or otherwise, either in connection with your account or to report a complaint or issue;</p>
                <p className="p-privacy-policy list">•  submitting a job application or CV to us;</p>
                <p className="p-privacy-policy">You are not obliged to provide your personal data to us. However, if you do not provide your personal data to us, we may not be able to provide services to you, respond to your queries, allow you onto our sites, or process your job application.</p>
                <h4 className="h4-privacy-policy">Information that we collect about you</h4>
                <p className="p-privacy-policy">We collect personal data about you:</p>
                <p className="p-privacy-policy list">•  when you use our app, details of your usage including login information, device identifiers (including but not limited to IMEI), usage patterns;</p>
                <p className="p-privacy-policy list">•  when you visit our website, including details of your visits to our website including, but not limited to, Internet Protocol (IP) address used to connect your computer to the internet, MAC addresses, traffic data, location data, your login information, time-zone setting browser type and version, browser plug-in types and versions, operating system and platform, weblogs, cookies and other communication data, and the resources that you access. For more information please see our cookies policy.</p>
            </div>
            <div className="div-privacy-policy">
                <h3 className="h3-privacy-policy">WHY DO WE NEED YOUR INFORMATION?</h3>
                <p className="p-privacy-policy">The main purpose for which we use your information is to provide you with the services that you request from us including but not limited to storing video footage recorded in our app and assessing driving risk for insurance purposes.</p>
                <p className="p-privacy-policy">We also use your information:</p>
                <p className="p-privacy-policy list">•  To provide you with information that you request from us or which we feel may interest you. We may, on occasion and, where appropriate where you have consented, send you marketing information by email or post. This can include notifications about the launch of new goods, services, competitions, offers or other relevant news articles that you might find interesting. If you would like to stop receiving such information from us, please click on the "unsubscribe" link in any such emails that we send to you, or use the contact details at the beginning of this policy;</p>
                <p className="p-privacy-policy list">•  for research about our customers’ behaviour and our products and services generally;</p>
                <p className="p-privacy-policy list">•  to develop and improve our services;</p>
                <p className="p-privacy-policy list">•  to administer and improve our website to ensure that content is presented in the most effective manner;</p>
                <p className="p-privacy-policy list">•  to notify you about changes to our goods and services;</p>
                <p className="p-privacy-policy list">•  to provide customer support;</p>
                <p className="p-privacy-policy list">•  to review your CV and suitability for a role;</p>
            </div>
            <div className="div-privacy-policy">
                <h3 className="h3-privacy-policy">Basis for using your information</h3>
                <h4 className="h4-privacy-policy">Necessary for the entry into or performance of a contract</h4>
                <p className="p-privacy-policy">When you enter into a transaction with us, a contract between you and us will have been entered into. In order for us to fulfil our obligations under such contract (e.g. to allow you to place an order for goods or services), we will need to collect, process and share (as further detailed below) your personal information. Failure to provide the requisite personal information when placing your order and financial information on entering into the transaction or objecting to this type of processing / exercising your deletion rights will unfortunately mean we cannot provide our goods and/or services to you.</p>
                <h4 className="h4-privacy-policy">Legitimate business interests</h4>
                <p className="p-privacy-policy">We use your personal data (excluding special categories of personal data) as set out in this privacy policy for the legitimate interests of our business to enable us to:</p>
                <p className="p-privacy-policy list">•  provide you with goods and services as requested by you;</p>
                <p className="p-privacy-policy list">•  carry out research to understand our customers and how they use our products and services;</p>
                <p className="p-privacy-policy list">•  develop and improve our services to you and to our other customers;</p>
                <p className="p-privacy-policy">As indicated below, we may also pass your personal data to members of our group and other third parties and this is also for our legitimate business interests.</p>
                <p className="p-privacy-policy">We are required to carry out a balancing test of our legitimate business interests in using your personal data outlined above against your interests and rights under the Data Protection Laws. As a result of our balancing test, which is detailed below, we have determined, acting reasonably and considering the circumstances, that we are able to process your personal data in accordance with the Data Protection Laws on the basis that we have a legitimate business interest.</p>
                <h4 className="h4-privacy-policy">Legitimate interest</h4>
                <p className="p-privacy-policy">We have a legitimate interest in processing your information as:</p>
                <p className="p-privacy-policy list">•  you benefit from the provision of goods and services;</p>
                <p className="p-privacy-policy list">•  we will both benefit from the ability to enforce or apply rights under any contract between us;</p>
                <p className="p-privacy-policy list">•  we have a legitimate interest in ensuring the security of our sites, and in assisting with the prevention and detection of crime;</p>
                <p className="p-privacy-policy list">•  we would be unable to provide our goods and services without processing your information.</p>
                <h4 className="h4-privacy-policy">Necessity</h4>
                <p className="p-privacy-policy">We consider that it is reasonable for us to process your personal data for the purposes of our legitimate interests outlined above as we process your personal data only so far as is necessary to achieve the purpose outlined in this privacy policy.</p>
                <h4 className="h4-privacy-policy">Impact of processing</h4>
                <p className="p-privacy-policy">We consider that it is reasonable for us to process your personal data for the purposes of our legitimate interests outlined above as the processing of your personal data does not unreasonably intrude on your privacy.</p>
                <h4 className="h4-privacy-policy">Consent</h4>
                <p className="p-privacy-policy">We may, on occasion, send you marketing messages by email and post about us and our events and offers where you have not unsubscribed and where you have purchased similar goods or services from us, or where you have otherwise consented.</p>
                <p className="p-privacy-policy">You have the right to withdraw your consent to processing of this nature at any time.</p>
            </div>
            <div className="div-privacy-policy">
                <h3 className="h3-privacy-policy">WHO DO WE SHARE YOUR INFORMATION WITH?</h3>
                <p className="p-privacy-policy">We may share your information with the other members of our group of companies but only for the purposes specified in this privacy policy.</p>
                <p className="p-privacy-policy">We may also use a number of carefully selected third parties to supply us with products and services, such as legal advice. We will only share your information with these suppliers where it is necessary for them to provide us with the services we need. We do not share your information with third parties for marketing purposes.</p>
            </div>
            <div className="div-privacy-policy">
                <h3 className="h3-privacy-policy">WHERE IS YOUR INFORMATION STORED AND HOW LONG DO WE KEEP IT FOR?</h3>
                <h4 className="h4-privacy-policy">Retention of your personal data</h4>
                <p className="p-privacy-policy">We will typically keep your personal data for 6 years from the closure of your account with us in order to enable us to deal with any issues or concerns you may have about how we handled your account, and also to allow us to bring or defend legal proceedings. In some circumstances, some of your data will be deleted in much shorter timescales, for example:</p>
                <p className="p-privacy-policy list">•  recruitment and interview records for unsuccessful candidates are kept for 1 month after the start date of the successful candidate;</p>
                <p className="p-privacy-policy list">•  cookies are refreshed in accordance with our cookies policy here;</p>
                <h4 className="h4-privacy-policy">Security of your personal data</h4>
                <p className="p-privacy-policy">Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our websites; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>
            </div>
            <div className="div-privacy-policy">
                <h3 className="h3-privacy-policy">WHAT RIGHTS DO YOU HAVE?</h3>
                <p className="p-privacy-policy">You have a number of rights under the Data Protection Laws in relation to the way we process your personal data, which are set out below. You may contact us using the details at the beginning of this privacy policy to exercise any of these rights.</p>
                <p className="p-privacy-policy">In some instances, we may be unable to carry out your request, in which case we will write to you to explain why.</p>
                <p className="p-privacy-policy">1. You have the right to request access to your personal data</p>
                <p className="p-privacy-policy">You have the right to request confirmation that your personal data is being processed, access to your personal data (through us providing a copy) and other information about how we process your personal data.</p>
                <p className="p-privacy-policy">2. You have the right to ask us to rectify your personal data</p>
                <p className="p-privacy-policy">You have the right to request that we rectify your personal data if it is not accurate or not complete.</p>
                <p className="p-privacy-policy">3. You have the right to ask us to erase your personal data</p>
                <p className="p-privacy-policy">You have the right to ask us to erase or delete your personal data where there is no reason for us to continue to process your personal data. This right would apply if we no longer need to use your personal data to provide the debt management services to you, where you withdraw your consent for us to process special categories of your personal data, or where you object to the way we process your personal data (see right 6 below).</p>
                <p className="p-privacy-policy">4. You have the right to ask us to restrict or block the processing of your personal data</p>
                <p className="p-privacy-policy">You have the right to ask us to restrict or block the processing of your personal data that we hold about you. This right applies where you believe the personal data is not accurate, you would rather we block the processing of your personal data rather than erase your personal data, where we don't need to use your personal data for the purpose for which we collected it but you may require it to establish, exercise or defend legal claims.</p>
                <p className="p-privacy-policy">5. You have the right to port your personal data</p>
                <p className="p-privacy-policy">You have the right to obtain and reuse your personal data from us to reuse for your own purposes across different services. This allows you to move personal data easily to another organisation, or to request us to do this for you.</p>
                <p className="p-privacy-policy">6. You have the right to object to our processing of your personal data</p>
                <p className="p-privacy-policy">You have the right to object to our processing of your personal data on the basis of our legitimate business interests, unless we are able to demonstrate that, on balance, our legitimate interests override your rights or we need to continue processing your personal data for the establishment, exercise or defence of legal claims.</p>
                <p className="p-privacy-policy">7. You have the right not to be subject to automated decisions</p>
                <p className="p-privacy-policy">You have the right to object to any automated decision making, including profiling, where the decision has a legal or significant impact on you.</p>
                <p className="p-privacy-policy">8. You have the right to withdraw your consent</p>
                <p className="p-privacy-policy">You have the right to withdraw your consent where we are relying on it to use your personal data.</p>
            </div>
            <div className="div-privacy-policy">
                <h3 className="h3-privacy-policy">WHAT IF YOU HAVE A COMPLAINT?</h3>
                <p className="p-privacy-policy">If you have any concerns regarding our processing of your personal data, or are not satisfied with our handling of any request may by you, or would otherwise like to make a complaint, please contact our Data Protection Officer in the first instance using the details at the start of this privacy policy, so that they can do their very best to sort out the problem.</p>
                <p className="p-privacy-policy">You can also contact the Information Commissioner's Office at Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF <a href="http://www.ico.org.uk.">www.ico.org.uk.</a></p>
            </div>
            <div className="div-privacy-policy">
                <h3 className="h3-privacy-policy">SOLE TRADERS, PARTNERSHIPS AND BUSINESS</h3>
                <p className="p-privacy-policy">Where we provide products and/or services for a sole trader, partnership or business that you represent or are employed / contracted by and this involves the collection and use of your personal data by us, this will be done in accordance with the relevant parts of this privacy policy.</p>
            </div>
            <div className="div-privacy-policy">
                <h3 className="h3-privacy-policy">OTHER WEBSITES</h3>
                <p className="p-privacy-policy">Our websites may, from time to time, contain links to and from the websites of third parties. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies or your use of those websites.</p>
            </div>
            <div className="div-privacy-policy">
                <h3 className="h3-privacy-policy">CHANGES TO THIS PRIVACY POLICY</h3>
                <p className="p-privacy-policy">We will take all measures necessary to communicate any changes to this privacy policy to you, and will post any updated privacy policies on this page.</p>
                <p className="p-privacy-policy">This policy was last reviewed and updated in December 2020.</p>
            </div>
        </>
    );
}

export default PrivacyPolicy;