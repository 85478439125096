import React from "react";
import { useTranslation } from 'react-i18next'; 
import "./../styles/Industries.css";
import Collapsible from "../components/Collapsible";
import { Button } from "../components/Button";

const Industries = () => {
    const { t, i18n } = useTranslation(); // Added i18n

    const banner = t('IndustriesData.banner', { returnObjects: true });
    const categories = t('IndustriesData.categories', { returnObjects: true });
    const cta = t('IndustriesData.cta', { returnObjects: true });

    const buttonFullWidthStyle = {
        width: '100%',
    };

    return ( 
        <>
            <div className="Industries-banner-container">
                <div className="Industries-banner">
                    <h1 className="Industries-category-header button-m-semibold rooya-text-gradient">
                        {i18n.language === 'ar' ? 'كيف يعمل' : 'How It Works'}
                    </h1>
                    <h2 className="Industries-banner-title h5-semibold">
                        {banner.title}
                    </h2>
                    <p className="Industries-banner-description body-m-regular">
                        {banner.description}
                    </p>
                </div>
            </div>

            <div className="Industries-main-container">
                {categories.map((category, index) => (
                    <div key={index} className="Industries-faq-container">
                        <div className="Industries-categories">
                            <h1 className="Industries-category-header button-m-semibold rooya-text-gradient">
                                {category.header}
                            </h1>
                            <p className="Industries-category-name h6-semibold">
                                {category.name}
                            </p>
                        </div>
                        <div className="Industries-questions">
                            {category.questions.map((question, qIndex) => (
                                <div key={qIndex} className="Industries-banner-item">
                                    <Collapsible
                                        open={qIndex === 0 ? "true" : "false"}
                                        label={
                                            <div className="Industries-question-header">
                                                <img src={question.icon} alt="icon" className="Industries-question-icon" />
                                                <span>{question.question}</span>
                                            </div>
                                        }
                                        content={
                                            <div>
                                                <p className="Industries-answer-text">{question.answer.text}</p>
                                                <ul>
                                                    {question.answer.bullets.map((bullet, bIndex) => (
                                                        <li key={bIndex}>{`• ${bullet}`}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            <div className="Industries-cta-wrapper">
                <div className="Industries-cta-container">
                    <div className="Industries-cta">
                        <span className="Industries-cta-tag button-s-semibold">{cta.tag}</span>
                        <h2 className="Industries-cta-title h5-semibold">{cta.title}</h2>
                        <p className="Industries-cta-description body-m-regular">{cta.description}</p>
                        <div className="Industries-cta-buttons">
                            {cta.buttons.map((button, index) => (
                                <Button key={index} color={button.color} path={button.path} style={buttonFullWidthStyle}>
                                    <span className="button-m-semibold">{button.text}</span>
                                </Button>
                            ))}
                        </div>
                    </div>
                    <div className="Industries-cta-image">
                        <img src={cta.image} alt="Industries management" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Industries;
