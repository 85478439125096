import React from "react";
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import "./../styles/Support.css";
import "./../styles/Typography.css";
import FeaturesSlider from "../components/FeaturesSlider";
import Collapsible from "../components/Collapsible";
import Divider from "../components/Divider";
import LetsTalk from "../components/LetsTalk";

const Support = () => {
    const { t } = useTranslation(); // Initialize the translation hook

    // Fetch translated data
    const banner = t('supportData.banner', { returnObjects: true });
    const categories = t('supportData.categories', { returnObjects: true });

    return (
        <>
            {/* <!-- Banner start --> */}
            <div className='support-banner-container'>
                <div className="support-banner">
                    <div className='button-m-semibold rooya-text-gradient'>{banner.header}</div>
                    <h1 className='support-banner-title h5-semibold grays-600'>{banner.title}</h1>
                    <p className='support-banner-description body-m-regular grays-400'>{banner.description}</p>
                </div>
                <div className="support-banner support-info">
                    {banner.contact.map((feature, index) => (
                        <div key={index} className='support-banner-item'>
                            <div className="support-banner-icon-background" style={{ '--dynamic-bg-color': feature.colour }}>
                                <img src={feature.icon} alt={feature.title} className='support-banner-icon' />
                            </div>
                            <div className="support-item-details">
                                <h2 className='body-s-regular grays-400'>{feature.title}</h2>
                                <p className='body-m-semibold grays-500'>{feature.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* <!-- Banner end --> */}

            {/* <!-- FAQ start --> */}
            <div className=''>
                {categories.map((category, index) => (
                    <div key={index} className="support-faq-container">
                        <div className="support-categories">
                            <h1 className='support-banner-title button-m-semibold rooya-text-gradient'>{category.header}</h1>
                            <p className='support-banner-description h6-semibold grays-000'>{category.name}</p>
                        </div>
                        <div className="support-questions">
                            {category.questions.map((question, qIndex) => (
                                <div key={qIndex} className='support-banner-item'>
                                    <Collapsible open={qIndex === 0 ? "true" : "false"} label={question.question} content={question.answer} />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            {/* <!-- FAQ end --> */}

            {/* <!-- Let's talk start --> */}
            <LetsTalk />
            {/* <!-- Let's talk end --> */}
        </>
    );
}

export default Support;
