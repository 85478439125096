import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'; 
import "./../styles/LetsTalk.css";
import "./../styles/Typography.css";
import "./../styles/App.css";
import Divider from "../components/Divider";

const LetsTalk = () => {
    const { t } = useTranslation(); 

    const talk = t('letsTalkData.talk', { returnObjects: true });

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        agreeToTerms: false,
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });

        setErrors({
            ...errors,
            [name]: '',
        });
    };

    const validateForm = () => {
        const newErrors = {};
        
        if (!formData.name.trim()) {
            newErrors.name = "Name is required";
        }
        if (!formData.email.trim()) {
            newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Email is invalid";
        }
        if (!formData.message.trim()) {
            newErrors.message = "Message is required";
        }

        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setIsSubmitting(true);

        try {
            const response = await fetch('https://fleet-dev.polysurance.com:6021/polyDrive/v1/contact-us', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setSuccessMessage("Message sent successfully!");
                setFormData({
                    name: '',
                    email: '',
                    message: '',
                    agreeToTerms: false,
                });
                setErrors({});
            } else {
                console.error("Failed to send message");
                setSuccessMessage("");
            }
        } catch (error) {
            console.error("Error sending message:", error);
            setSuccessMessage("");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className='talk-container grays-500'>
            <div className="talk-info">
                <div className="talk-description">
                    <h1 className='h2-semibold'>{talk.info.title}</h1>
                    <p className='body-s-regular'>{talk.info.description}</p>
                    <p className='body-s-regular'>{talk.info.listDescription}</p>
                    <ul>
                        {talk.info.listItems.map((item, index) => (
                            <li key={index} className='talk-list-item'>
                                <img src={talk.info.listImg} alt="" />
                                <p className='body-s-regular'>{item}</p>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="talk-divider-faq-desktop"><Divider height='2px' background="linear-gradient(to right, rgba(34, 33, 33, 0.6), rgba(104, 101, 101, 0.6), rgba(35, 34, 34, 0.6))" /></div>
                <div className='talk-contact-details'>
                 {talk.info.contactDetails.map((contact, index) => (
                  <div key={index} className='talk-item-details'>
                   <p className='body-s-regular grays-200'>{contact.label}</p>
                    <p className='button-s-semibold grays-500'>
                      {contact.label === "رقم الهاتف" ? (
                    <span style={{ direction: 'ltr', unicodeBidi: 'bidi-override' }}>{contact.value}</span>
                       ) : (
                         contact.value
                      )}
                  </p>
              </div>
                    ))}
               </div>
            </div>
            <div className="talk-divider-faq-mobile"><Divider height='2px' background="linear-gradient(to right, rgba(34, 33, 33, 0.6), rgba(104, 101, 101, 0.6), rgba(35, 34, 34, 0.6))" /></div>
            <form className="talk-form" onSubmit={handleSubmit}>
                <div className="talk-form-inputs">
                    <div className={`talk-form-field ${errors.name ? 'error' : ''}`}>
                        <label className='body-s-medium grays-500'>{talk.form.name.label}</label>
                        <input
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="rooya-input body-s-regular"
                            placeholder={talk.form.name.placeholder}
                        />
                        {errors.name && <p className="error-message">{errors.name}</p>}
                    </div>
                    <div className={`talk-form-field ${errors.email ? 'error' : ''}`}>
                        <label className='body-s-medium grays-500'>{talk.form.email.label}</label>
                        <input
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="rooya-input body-s-regular"
                            placeholder={talk.form.email.placeholder}
                        />
                        {errors.email && <p className="error-message">{errors.email}</p>}
                    </div>
                </div>
                <div className={`talk-form-field rooya-textarea-parent ${errors.message ? 'error' : ''}`}>
                    <label className='body-s-medium grays-500'>{talk.form.message.label}</label>
                    <textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        className="rooya-textarea body-s-regular"
                        placeholder={talk.form.message.placeholder}
                    />
                    {errors.message && <p className="error-message">{errors.message}</p>}
                </div>
                <div className="rooya-checkbox-container">
                    <input
                        name="agreeToTerms"
                        type="checkbox"
                        checked={formData.agreeToTerms}
                        onChange={handleChange}
                        className="rooya-checkbox"
                    />
                    <span className="grays-500 body-s-regular">{talk.form.checkbox.label}</span>
                </div>
                <button type="submit" className="rooya-form-submit button-m-semibold" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting..." : successMessage || talk.form.submitButton.text}
                </button>
                <p className="rooya-self-align-center">
                    <span className="body-xs-regular grays-300">{talk.form.tandc.text}</span>
                    <a href="/terms-conditions" className="body-xs-regular grays-400 rooya-a-href">{talk.form.tandc.tc}</a>
                </p>
            </form>
        </div>
    );
};

export default LetsTalk;
