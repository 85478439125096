export const TechnologyData = {
    banner: {
        title: "Enhance Fleet Management with AI-Driven Solutions",
        description:
            "Our technology integrates artificial intelligence and vision-based systems to provide real-time insights and proactive management tools for fleets across various industries.",
        ctaButton: {
            text: "Book a demo?",
            path: "/contact-us"
        },
    },
    cards: [
        {
            title: "AI-Driven Vision Systems",
            icon: "../img/T1.svg",
            content: {
                description: "Our advanced AI-vision systems utilize high-definition dual dash-cams to capture and analyze critical driving data. Equipped with real-time video analysis, our technology detects and responds to unsafe driving behaviors, such as distractions and fatigue, enhancing overall road safety.",
                bullets: [
                    "Dual HD dash-cams with integrated GPS",
                    "Real-time AI video analysis",
                    "Advanced driver-assistance systems (ADAS)",
                    "Driver monitoring systems (DMS)"
                ]
            }
        },
        {
            title: "Seamless Integration",
            icon: "../img/T2.svg",
            content: {
                description: "Our technology integrates effortlessly with existing fleet management systems. With easy API integration, our solutions ensure a smooth transition and minimal disruption to your operations. We offer support for real-time notifications and alerts to keep you informed of critical incidents and performance issues.",
                bullets: [
                    "Effortless API integration",
                    "Real-time alerts and notifications",
                    "Customizable reporting and dashboards"
                ]
            }
        },
        {
            title: "Innovative Connectivity",
            icon: "../img/T3.svg",
            content: {
                description: "Stay connected with our advanced connectivity solutions. Our devices feature built-in 4G LTE, dual-band Wi-Fi, and Bluetooth for seamless data transfer and firmware updates. Continuous improvements are ensured through automatic software updates and integration with your existing systems.",
                bullets: [
                    "Built-in 4G LTE and dual-band Wi-Fi",
                    "Automatic firmware updates",
                    "Bluetooth 5.0 BLE connectivity"
                ]
            }
        },
        {
            title: "Comprehensive Data Analytics",
            icon: "../img/T4.svg",
            content: {
                description: "Rooya's telematics solutions offer robust data analytics to help you make informed decisions. Our platform provides detailed risk analysis reports and performance metrics, enabling you to identify trends, assess risk factors, and improve fleet management strategies.",
                bullets: [
                    "In-depth risk analysis and reporting",
                    "Real-time data collection and visualization",
                    "Secure cloud-based storage"
                ]
            }
        },
        {
            title: "Enhanced Safety Features",
            icon: "../img/T5.svg",
            content: {
                description: "Safety is our top priority. Our technology includes features designed to prevent accidents and improve driver behavior. With tools such as emergency recording, collision prevention technology, and real-time feedback, Rooya AI-telematics is dedicated to protecting your fleet and ensuring safer roads.",
                bullets: [
                    "Emergency button for instant recording",
                    "Pseudo-LIDAR technology for collision avoidance",
                    "Real-time driver behavior alerts"
                ]
            }
        }
    ],

    cta: {
        tag: "Contact us",
        title: "Ready to optimise your fleet operations?",
        description:
            "Elevate driving standards, prioritize safety, and optimize your operations with our advanced solutions.",
        buttons: [
            {
                text: "Book a demo",
                color: "green",
                path: "/contact-us"
            }
        ],
        image: "../img/Mockup-wrap.svg"
    }
};
