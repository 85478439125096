export const IndustriesData = {
  banner: {
    header: "Driving Innovation Across Industries",
    title: "Solutions Tailored to Your Sector",
    description:
      "Our advanced solutions cater to a diverse range of industries, each with unique needs for fleet management, safety, and efficiency",
  },

  categories: [
    {
      header: "Explore Our Expertise",
      name: "Industry-Specific Solutions",
      questions: [
        {
          question: "Logistics and transportation",
          icon: "/img/Icons.svg", 
          answer: {
            text: "Streamline your logistics operations with real-time vehicle tracking and performance analytics. Our solutions help optimize routes, reduce fuel consumption, and improve overall fleet efficiency, ensuring timely and reliable deliveries.",
            bullets: [
              "Efficient route planning",
              "Fuel consumption monitoring",
              "Enhanced delivery reliability"
            ]
          }
        },
        {
          question: "Construction",
          icon: "/img/Icons-1.svg",  
          answer: {
            text: "Enhance safety and productivity on construction sites with our robust telematics solutions. Monitor equipment and vehicle usage, track job site operations, and ensure compliance with safety regulations.",
            bullets: [
              "Equipment utilization tracking",
              "Site safety monitoring",
              "Compliance with regulations"
            ]
          }
        },
        {
          question: "Delivery and E-commerce",
          icon: "/img/Icons-2.svg",  
          answer: {
            text: "Ensure swift and safe deliveries with comprehensive fleet management tools. Our technology provides real-time tracking and performance insights to help you manage delivery operations effectively and maintain high service levels.",
            bullets: [
              "Real-time delivery tracking",
              "Performance insights",
              "Service level management"
            ]
          }
        },
        {
          question: "Government agencies",
          icon: "/img/Icons-3.svg",  
          answer: {
            text: "Improve the efficiency and safety of government vehicle fleets with our advanced telematics solutions. Our technology supports fleet management, ensures compliance, and enhances operational effectiveness.",
            bullets: [
              "Fleet management support",
              "Compliance assurance",
              "Operational effectiveness"
            ]
          }
        },
        {
          question: "Oil, petrochemicals and gas",
          icon: "/img/Icons-4.svg",  
          answer: {
            text: "Monitor and manage your specialized fleets in the oil, petrochemicals, and gas sectors with precision. Our solutions offer real-time tracking, risk management, and safety features tailored to high-risk environments.",
            bullets: [
              "Real-time fleet monitoring",
              "Risk management",
              "Safety enhancements"
            ]
          }
        },
        {
          question: "Food and beverage",
          icon: "/img/Icons-5.svg",  
          answer: {
            text: "Optimize your food and beverage distribution with our fleet management solutions. Ensure timely deliveries, maintain product quality, and enhance operational efficiency with our advanced technology.",
            bullets: [
              "Timely deliveries",
              "Product quality maintenance",
              "Operational efficiency"
            ]
          }
        },
        {
          question: "Manufacturing",
          icon: "/img/Icons-6.svg",  
          answer: {
            text: "Boost productivity and operational efficiency in manufacturing with real-time vehicle and equipment tracking. Our solutions help streamline operations, monitor usage, and ensure compliance with safety standards.",
            bullets: [
              "Productivity enhancement",
              "Usage monitoring",
              "Safety compliance"
            ]
          }
        },
        {
          question: "Retail distribution",
          icon: "/img/Icons-7.svg", 
          answer: {
            text: "Enhance the efficiency of retail distribution with our telematics solutions. Manage your fleet’s performance, optimize routes, and ensure timely deliveries to meet customer expectations.",
            bullets: [
              "Fleet performance management",
              "Route optimization",
              "Timely deliveries"
            ]
          }
        },
        {
          question: "Banking and security transport",
          icon: "/img/Icons-8.svg",  
          answer: {
            text: "Ensure the safety and security of banking and security transport vehicles with our advanced monitoring technology. Our solutions provide comprehensive tracking and risk management to protect valuable assets.",
            bullets: [
              "Vehicle safety and security",
              "Comprehensive tracking",
              "Risk management"
            ]
          }
        },
        {
          question: "Taxi and ride-sharing",
          icon: "/img/Icons-9.svg", 
          answer: {
            text: "Improve the safety and efficiency of taxi and ride-sharing services with our telematics solutions. Monitor driver behavior, enhance passenger safety, and optimize fleet management for better service quality.",
            bullets: [
              "Driver behavior monitoring",
              "Passenger safety enhancement",
              "Fleet management optimization"
            ]
          }
        },
        {
          question: "Corporate fleet management",
          icon: "/img/Icons-10.svg",  
          answer: {
            text: "Streamline the management of corporate fleets with our advanced telematics solutions. Gain real-time insights into fleet performance, optimize operations, and improve overall efficiency.",
            bullets: [
              "Real-time performance insights",
              "Operational optimization",
              "Efficiency improvements"
            ]
          }
        }
      ]
    }
  ],
  
  cta: {
    tag: "Contact us",
    title: "Ready to optimize your fleet operations?",
    description:
        "Unlock the full potential of your fleet with our cutting-edge solutions designed to enhance safety, efficiency, and compliance across all industries.",
    buttons: [
        {
            text: "Book a demo",
            color: "green",
            path: "/contact-us"
        }
    ],
    image: "/img/cta-img.png"
  }
}
