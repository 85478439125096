import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/Navbar.css";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FaCheck } from "react-icons/fa";

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const toggleRef = useRef(null);
    const langMenuRef = useRef(null);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 744) {
                setIsMenuOpen(false);
                setOpenDropdown(null);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleDropdownToggle = (index) => {
        setOpenDropdown(openDropdown === index ? null : index);
    };

    const handleClickOutside = (e) => {
        if (
            menuRef.current && !menuRef.current.contains(e.target) &&
            !toggleRef.current.contains(e.target) &&
            !langMenuRef.current.contains(e.target)
        ) {
            setIsMenuOpen(false);
            setOpenDropdown(null);
            setIsLangMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeAllMenus = () => {
        setIsMenuOpen(false);
        setOpenDropdown(null);
        setIsLangMenuOpen(false);
    };

    const handleLinkClick = (url) => {
        closeAllMenus();
        navigate(url);
    };

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang).then(() => {
            window.location.reload(); // Reload the page after language change
        });
    };

    return (
        <nav className='navbar'>
            <div className='navbar-container'>
                <Link to={t('navbarData.logo.url')} className='navbar-logo' onClick={closeAllMenus}>
                    <img src={t('navbarData.logo.image')} alt='logo' />
                </Link>
                <div ref={menuRef} className={`navbar-menu ${isMenuOpen ? "active" : ""}`}>
                    {t('navbarData.menuItems', { returnObjects: true }).map((item, index) => (
                        <div key={index} className='navbar-item'>
                            {item.hasDropdown ? (
                                <button
                                    className='navbar-link dropdown-toggle body-s-medium'
                                    onClick={() => handleDropdownToggle(index)}
                                >
                                    {item.text}
                                    <span className='chevron-icon'></span>
                                </button>
                            ) : (
                                <button className='navbar-link body-s-medium' onClick={() => handleLinkClick(item.url)}>
                                    {item.text}
                                </button>
                            )}
                            {item.hasDropdown && (
                                <div className={`navbar-dropdown ${openDropdown === index ? "active" : ""}`}>
                                    {item.dropdownItems.map((dropdownItem, dropdownIndex) => (
                                        <button
                                            key={dropdownIndex}
                                            className='navbar-dropdown-link body-s-medium'
                                            onClick={() => handleLinkClick(dropdownItem.url)}
                                        >
                                            {dropdownItem.text}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {/* Language Switcher */}
                <div className="navbar-lang" ref={langMenuRef}>
                    <FontAwesomeIcon 
                        icon={faGlobe} 
                        className="navbar-lang-icon"
                        onClick={() => setIsLangMenuOpen(!isLangMenuOpen)} 
                    />
                    {isLangMenuOpen && (
                        <div className={`navbar-lang-dropdown ${isLangMenuOpen ? "active" : ""}`}>
                            <button 
                                className={`navbar-lang-option ${i18n.language === 'en' ? 'active' : ''}`}
                                onClick={() => changeLanguage('en')}
                            >
                                <span className="navbar-lang-flag">🇬🇧</span>
                                English
                                {i18n.language === 'en' && <FaCheck className="check-icon" />}
                            </button>
                            <button 
                                className={`navbar-lang-option ${i18n.language === 'ar' ? 'active' : ''}`}
                                onClick={() => changeLanguage('ar')}
                            >
                                <span className="navbar-lang-flag">🇸🇦</span>
                                العربية
                                {i18n.language === 'ar' && <FaCheck className="check-icon" />}
                            </button>
                        </div>
                    )}
                </div>

                <button className='navbar-cta button-s-semibold' onClick={() => handleLinkClick(t('navbarData.cta.url'))}>
                    {t('navbarData.cta.text')}
                </button>
                <button ref={toggleRef} className='navbar-toggle' onClick={toggleMenu}>
                    <span className='navbar-toggle-icon'></span>
                </button>
            </div>
        </nav>
    );
}

export default Navbar;
