export const DriversAppData = {
    hero: {
        title: "صمم لتمكين السائقين عن طريق الأدوات و الرؤى الأساسية",
        description:
            `يوفر التطبيق البيانات بشكل فوري ومقياس الأداء و الملاحظات على الأداء, 
            مما يجعله أداة لا غنى عنها لسائقي الأساطيل`,
        ctaButton: {
            text: "تريد عرض توضيحي؟",
            path: "/contact-us"
        },
        image: "./../assets/Hand.svg",
        playStoreButton: {
            svg: "./../assets/Mobile app store badge-1.svg",
            alt: "احصل على التطبيق من خلال متجر التطبيقات للاندرويد",
            link: "https://play.google.com/store/apps/details?id=com.polydrive&hl=en_GB"
        },
        appStoreButton: {
            svg: "./../assets/Mobile app store badge.svg",
            alt: "احصل على التطبيق من خلال متجر التطبيقات لأبل",
            link: "https://apps.apple.com/gb/app/polydrive/id6480055464"
        }
    },
    features: {
        headerTitle: "أدوات أساسية لجميع السائقين",
        headerDescription: "تعزيز أداء السائق بدقة",
        items: [
            {
                title: "تحسين سلوك السائق",
                description:
                    "تعمل المراقبة المستمرة والملاحظات على الأداء على تعزيز عادات القيادة الأكثر أمانًا وكفاءة.",
                icon: "./../assets/Devices-1.svg"
            },
            {
                title: "تعزيز السلامة",
                description:
                    "تساعد الملاحظات والتنبيهات المباشرة للسائقين على تجنب السلوكيات المحفوفة بالمخاطر، مما يساهم في جعل الطرق أكثر أمانًا",
                icon: "./../assets/Devices.svg"
            },
            {
                title: "تعزيز الكفاءة",
                description:
                    "استفد من بيانات الأداء الشاملة لتحسين عمليات الاكتتاب وتقييم المخاطر المحتملة بشكل أفضل.",
                icon: "./../assets/Devices-2.svg"
            },
            {
                title: "تواصل أفضل",
                description:
                    "تسهيل التواصل السلس بين السائقين ومديري الأسطول، لضمان التحديثات في الوقت المناسب و تنسيق الجهود.",
                icon: "./../assets/Devices-3.svg"
            }
        ]
    },
    additionalFeatures: [
        {
            title: "التثبيت السريع",
            description: 
                `قم بتنزيل التطبيق على هاتفك، وقم بتسجيل الدخول باستخدام بيانات الحساب الخاصة بك، 
                وابدأ على الفور.`,
            image: "./../assets/PD.svg"
        },
        {
            title: "تحليل الأداء القائم على الذكاء الاصطناعي",
            description: 
                `استخدم الذكاء الاصطناعي للحصول على رؤى عميقة حول أنماط القيادة، 
                مما يساعد السائقين ومديري الاسطول على اتخاذ القرارات الصحيحة.`,
            image: "./../assets/PD.svg"
        },
        {
            title: "راجع، عدل، حسن",
            description: 
                `بعد كل رحلة، قم بمراجعة نتائج الأداء وتاريخ الرحلة. 
                استخدم هذه الرؤى لإجراء التعديلات والتحسين المستمر لسلوك القيادة.`,
            image: "./../assets/PD.svg"
        }
    ],
    cards: {
        items: [
            {
                title: "مراقبة شاملة للسائق",
                image: "./../assets/image.svg",
                description: 
                    `تابع سلوكيات القيادة الآمنة من خلال النتائج التفصيلية والتحليلات المرئية. 
                    قم بمراجعة الرحلات والحوادث وتصنيفات السلامة لتعزيز الالتزام بأفضل ممارسات القيادة.`
            },
            {
                title: "تنبيهات لحظية",
                image: "./../assets/image-1.svg",
                description: 
                    `احصل على إشعارات فورية للأحداث الهامة مثل السرعة والتوقف المفاجئ ومغادرة المسار. 
                    تعمل التنبيهات القابلة للتخصيص على تمكين السائقين من التركيز على مجالات محددة للتحسين.`
            },
            {
                title: "تاريخ الرحلات المتعمق",
                image: "./../assets/image-2.svg",
                description: 
                    `يمكنك الوصول إلى السجلات التفصيلية لكل رحلة، بما في ذلك أوقات البدء والانتهاء والمسارات وتقارير الحوادث. 
                    استخدم هذه الرؤى لتحديد الأنماط وتحسين الأداء العام.`
            },
            {
                title: "وصول عالمي مع دعم متعدد اللغات",
                image: "./../assets/image-3.svg",
                description: 
                    `متوفر بلغات متعددة بما في ذلك الإنجليزية والعربية والأردية والبنغالية والهندية لخدمة مجتمعات السائقين المتنوعة بشكل فعال.`
            },
            {
                title: "التقارير اليومية",
                image: "./../assets/image-4.svg",
                description: 
                    `احصل على ملخصات يومية مخصصة لأداء القيادة والمقاييس الرئيسية. 
                    ركز على التحسين المستمر من خلال رؤى قابلة للتنفيذ يتم إرسالها مباشرة إلى جهازك.`
            },
            {
                title: "سهل التركيب والاستخدام",
                image: "./../assets/image-5.svg",
                description: 
                    `واجهة مصممة لسهولة الاستخدام، وتشجع السائقين على التفاعل مع التطبيق وتحسين عادات القيادة لديهم.`
            }
        ]
    },
    cta: {
        tag: "تواصل معنا",
        title: "جاهز لتحسين عمليات أسطولك؟",
        description:
            "عزز أداء القيادة، وتأكد من السلامة، وقم بتبسيط عملياتك باستخدام التكنولوجيا المتطورة لدينا.",
        buttons: [
            {
                svg: "./../assets/Mobile app store badge.svg",
                alt: "احصل على التطبيق من خلال متجر التطبيقات للاندرويد",
                link: "https://play.google.com/store/apps/details?id=com.polydrive&hl=en_GB"
            },
            {
                svg: "./../assets/Mobile app store badge-1.svg",
                alt: "احصل على التطبيق من خلال متجر التطبيقات لأبل",
                link: "https://apps.apple.com/gb/app/polydrive/id6480055464"
            }
        ],
        image: "./../assets/Graphics.svg"
    }
};
