export const navbarData = {
    logo: {
        image: "/img/logo.svg",
        url: "/"
    },
    menuItems: [
        {
            text: "الحلول",
            url: "/solutions",
            hasDropdown: true,
            dropdownItems: [
                { text: "للأساطيل", url: "/fleet" },
                { text: "للتأمين", url: "/insurers" }
            ]
        },
        { text: "التقنية", url: "/technology" },
        { text: "تطبيق السائق", url: "/drivers-app" },
        { text: "القطاعات", url: "/industries" },
        { text: "الدعم", url: "/support" }
    ],
    cta: {
        text: "احجز عرضًا توضيحيًا",
        url: "/contact-us"
    }
 };
 