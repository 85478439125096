import React from "react";
import { useTranslation } from 'react-i18next'; 
import "./../styles/Technology.css";
import { Button } from "../components/Button";

const TechnologyPage = () => {
    const { t, i18n } = useTranslation(); 

    const banner = t('TechnologyData.banner', { returnObjects: true });
    const cards = t('TechnologyData.cards', { returnObjects: true });
    const cta = t('TechnologyData.cta', { returnObjects: true });

    const totalCards = 9;
    const filledCards = cards;
    const emptyCardsCount = totalCards - filledCards.length;

    const cardsDesktop = [
        ...Array(Math.floor(emptyCardsCount / 2)).fill({ empty: true }),
        ...filledCards,
        ...Array(Math.ceil(emptyCardsCount / 2)).fill({ empty: true })
    ];

    const cardsMobile = [
        ...Array(Math.floor(emptyCardsCount / 4)).fill({ empty: true }),
        ...filledCards,
        ...Array(Math.ceil(emptyCardsCount / 4)).fill({ empty: true })
    ];

    return (
        <div className="technology-page">
            <div className="banner">
                <span className="rooya-text-gradient button-m-semibold">
                    {i18n.language === 'ar' ? 'التقنية' : 'Technology'}
                </span>
                <h1 className="banner-title h5-semibold">{banner.title}</h1>
                <p className="banner-description body-m-regular grays-400">{banner.description}</p>
                <a href={banner.ctaButton.path} className="banner-cta">
                    {banner.ctaButton.text}
                </a>
            </div>

            <div className="technology-page-content">
                <div className="cards-container desktop">
                    {cardsDesktop.map((card, i) => (
                        <div
                            key={i}
                            className={`card ${card.empty ? "empty" : ""} ${
                                i < emptyCardsCount / 2 ? "card-top" : ""
                            }`}
                            style={{
                                gridRow: `${i + 1} / ${i + 3}`,
                                gridColumn: `${(i % 2) + 1} / ${(i % 2) + 2}`
                            }}
                        >
                            {!card.empty && (
                                <>
                                    <div className="card-icon">
                                        <img src={card.icon} alt={card.title} />
                                    </div>
                                    <div className="card-content">
                                        <h2 className="card-title">{card.title}</h2>
                                        <p className="card-description">{card.content.description}</p>
                                        <ul className="card-bullets">
                                            {card.content.bullets.map((bullet, idx) => (
                                                <li key={idx}>{`• ${bullet}`}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>

                <div className="cards-container mobile">
                    {cardsMobile.map((card, i) => (
                        <div
                            key={i}
                            className={`card ${card.empty ? "empty" : ""} ${
                                i < emptyCardsCount / 2 ? "card-top" : ""
                            }`}
                        >
                            {!card.empty && (
                                <>
                                    <div className="card-icon">
                                        <img src={card.icon} alt={card.title} />
                                    </div>
                                    <div className="card-content">
                                        <h2 className="card-title">{card.title}</h2>
                                        <p className="card-description">{card.content.description}</p>
                                        <ul className="card-bullets">
                                            {card.content.bullets.map((bullet, idx) => (
                                                <li key={idx}>{bullet}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <div className="Technology-cta-wrapper">
                <div className="Technology-cta-container">
                    <div className="Technology-cta">
                        <span className="Technology-cta-tag button-s-semibold">{cta.tag}</span>
                        <h2 className="Technology-cta-title h5-semibold">{cta.title}</h2>
                        <p className="Technology-cta-description body-m-regular">{cta.description}</p>
                        <div className="Technology-cta-buttons">
                            {cta.buttons.map((button, index) => (
                                <Button key={index} color={button.color} path={button.path} style={{ width: "100%" }}>
                                    <span className="button-m-semibold">{button.text}</span>
                                </Button>
                            ))}
                        </div>
                    </div>
                    <div className="Technology-cta-image">
                        <img src={cta.image} alt={i18n.language === 'ar' ? 'إدارة الأسطول' : 'Fleet management'} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TechnologyPage;
