export const TechnologyData = {
    banner: {
        title: "تعزيز إدارة الأسطول باستخدام الحلول المعتمدة على الذكاء الاصطناعي",
        description:
            "تدمج تقنيتنا الذكاء الاصطناعي والأنظمة القائمة على الرؤية لتوفير رؤى في الوقت الفعلي وأدوات إدارة استباقية للأساطيل عبر مختلف الصناعات.",
        ctaButton: {
            text: "تريد عرض توضيحي؟",
            path: "/contact-us"
        },
    },
    cards: [
        {
            title: "أنظمة الرؤية المدعومة بالذكاء الاصطناعي",
            icon: "../img/T1.svg",
            content: {
                description: "تستخدم أنظمة الرؤية المدعومة بالذكاء الاصطناعي المتقدمة لدينا كاميرات مزدوجة عالية الدقة لالتقاط بيانات القيادة المهمة وتحليلها. بفضل تقنية تحليل الفيديو بشكل مباشر، تكتشف تقنيتنا سلوكيات القيادة غير الآمنة وتستجيب لها، مثل عوامل التشتيت والتعب، مما يعزز السلامة على الطريق بشكل عام.",
                bullets: [
                    "كاميرات مزدوجة عالية الدقة مع نظام تحديد المواقع العالمي المدمج",
                    "تحليل الفيديو بالذكاء الاصطناعي بشكل مباشر",
                    "أنظمة مساعدة السائق المتقدمة",
                    "أنظمة مراقبة السائق"
                ]
            }
        },
        {
            title: "التكامل السلس",
            icon: "../img/T2.svg",
            content: {
                description: "تتكامل تقنيتنا بسهولة مع أنظمة إدارة الأسطول الحالية. من خلال التكامل السهل لواجهة برمجة التطبيقات، تضمن حلولنا الانتقال السلس والحد الأدنى من التعطيل لعملياتك. نحن نقدم الدعم للإشعارات والتنبيهات في الوقت الفعلي لإبقائك على علم بالحوادث الخطيرة ومشكلات الأداء.",
                bullets: [
                    "تكامل واجهة برمجة التطبيقات بسهولة",
                    "التنبيهات والإشعارات بشكل مباشر",
                    "تقارير ولوحات معلومات قابلة للتخصيص"
                ]
            }
        },
        {
            title: "الاتصال المبتكر",
            icon: "../img/T3.svg",
            content: {
                description: "ابق على اتصال مع حلول الاتصال المتقدمة لدينا. تتميز أجهزتنا بشبكة 4G LTE مدمجة وشبكة Wi-Fi مزدوجة النطاق وBluetooth لنقل البيانات وتحديثات البرامج الثابتة بسلاسة. يتم ضمان التحسينات المستمرة من خلال تحديثات البرامج التلقائية والتكامل مع أنظمتك الحالية.",
                bullets: [
                    "شبكة 4G LTE مدمجة وشبكة Wi-Fi مزدوجة النطاق",
                    "تحديثات البرامج الثابتة التلقائية",
                    "اتصال عن طريق تقنية البلوتوث"
                ]
            }
        },
        {
            title: "تحليلات البيانات الشاملة",
            icon: "../img/T4.svg",
            content: {
                description: "توفر حلول رؤية التيليماتية المدعومة بالذكاء الاصطناعي تحليلات بيانات قوية لمساعدتك على اتخاذ القرارات الصحيحة. توفر منصتنا تقارير مفصلة عن تحليل المخاطر ومقاييس الأداء، مما يتيح لك تحديد الاتجاهات وتقييم عوامل الخطر وتحسين استراتيجيات إدارة الأسطول.",
                bullets: [
                    "تحليل المخاطر المتعمق وإعداد التقارير",
                    "جمع البيانات والتصور بشكل مباشر",
                    "تخزين آمن قائم على السحابة"
                ]
            }
        },
        {
            title: "ميزات السلامة المحسنة",
            icon: "../img/T5.svg",
            content: {
                description: "السلامة هي أولويتنا القصوى. تشتمل تقنيتنا على ميزات مصممة لمنع الحوادث وتحسين سلوك السائق. باستخدام أدوات مثل تسجيل الطوارئ، وتقنية منع الاصطدام، والتعليقات في الوقت الفعلي، تم تطوير رؤية التيليماتية المدعومة بالذكاء الاصطناعي لحماية أسطولك وضمان طرق أكثر أمانًا.",
                bullets: [
                    "زر الطوارئ للتسجيل الفوري",
                    "تقنية Pseudo-LIDAR لتجنب الاصطدام",
                    "تنبيهات عن سلوك السائق بشكل مباشر"
                ]
            }
        }
    ],

    cta: {
        tag: "تواصل معنا",
        title: "جاهز لتحسين عمليات أسطولك؟",
        description:
            "اكتشف كيف يمكن لحلول التيليماتية المدعومة بالذكاء الاصطناعي الخاصة بنا أن تعمل على تمكين عمليات الاكتتاب والمطالبات لديك بدقة.",
        buttons: [
            {
                text: "احجز عرضًا توضيحيًا",
                color: "green",
                path: "/contact-us"
            }
        ],
        image: "/img/cta-img.png"
    }
};
